import { AUDIO_MAX_DURATION, AUDIO_MIN_DURATION } from "~/common/constants";

export const RECORDING_STATES = {
  RECORDING: "recording",
  INACTIVE: "inactive",
  PAUSED: "paused",
} as const;

export const formatTitleDuration = (duration: number) => {
  if (duration < 60) {
    return `${duration} ${duration == 1 ? "second" : "seconds"}`;
  }
  const minutes = Math.floor(duration / 60);
  return `${minutes} ${minutes == 1 ? "minute" : "minutes"}`;
};

export const getDescriptiveText = (
  recordingTimer: number,
  isRecording: boolean,
  superTitle?: string,
) => {
  const introduction = superTitle ?? "";
  if (recordingTimer === 0 && !isRecording) {
    return `${introduction} Record an audio between ${formatTitleDuration(
      AUDIO_MIN_DURATION,
    )} and ${formatTitleDuration(AUDIO_MAX_DURATION)}`;
  }
  if (recordingTimer === AUDIO_MAX_DURATION && !isRecording) {
    return `${formatTitleDuration(AUDIO_MAX_DURATION)} limit reached!`;
  }
  if (isRecording && recordingTimer < AUDIO_MIN_DURATION) {
    return `Please record at least ${formatTitleDuration(AUDIO_MIN_DURATION)}`;
  }
};

export const HealthIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#DDE7FD" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#97B8F7" />
    <path
      d="M16.0555 24.5C17.8646 26.3314 20.095 28.3797 21.0384 29.2368C21.1971 29.381 21.2765 29.4531 21.3691 29.4815C21.4499 29.5062 21.5383 29.5062 21.6191 29.4815C21.7117 29.4531 21.791 29.381 21.9498 29.2368C22.8932 28.3797 25.1235 26.3314 26.9326 24.5C27.844 23.5774 28.6485 22.7097 29.1277 22.0771C29.498 21.5883 29.7761 21.055 29.9622 20.5H22.772C22.588 20.5 22.4198 20.607 22.3375 20.7764L20.9969 23.5358C20.8119 23.9165 20.2787 23.9004 20.1157 23.5091L17.9666 18.3484C17.8146 17.9834 17.3298 17.9376 17.1158 18.268L15.8142 20.2774C15.7241 20.4164 15.5724 20.5 15.4099 20.5H13.0293C12.8141 19.8506 12.7261 19.172 12.7631 18.5C12.841 17.0837 13.4737 15.6969 14.6392 14.6753C16.8299 12.755 19.7456 13.3191 21.4941 15.4162C23.2426 13.3191 26.1206 12.7752 28.349 14.6753C28.9626 15.1985 29.4279 15.8227 29.7439 16.5"
      stroke="#6192F3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

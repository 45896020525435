import { useQueryClient } from "@tanstack/react-query";

export const useInvalidateTeamMember = () => {
  const queryClient = useQueryClient();

  const invalidateTeamMemberData = () =>
    queryClient.invalidateQueries({
      queryKey: ["getCurrentTeamMember"],
    });

  return { invalidateTeamMemberData };
};

import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { UseFormRegisterReturn } from "react-hook-form";

import { PlusIcon } from "~/common/components/icons";
import TextArea from "~/common/components/ui/Textarea";
import { scrollToBottom } from "~/common/utils/scrollToBottom";

interface NoteInputProps {
  register: UseFormRegisterReturn;
  description: string;
  placeholder: string;
  id: string;
  error?: string;
}

export const NoteInput = ({
  register,
  description,
  placeholder,
  id,
  error,
}: NoteInputProps) => {
  const [showTextArea, setShowTextArea] = useState(false);
  const toggleTextArea = () => {
    setShowTextArea((prev) => !prev);
  };

  useEffect(() => {
    showTextArea && scrollToBottom(id);
  }, [showTextArea, id]);

  return (
    <div className="flex w-full flex-col gap-1.5" id={id}>
      <button
        className="flex items-center gap-2"
        onClick={toggleTextArea}
        type="button"
      >
        <PlusIcon className="fill-primary-100 text-primary" />
        <p className="text-sm font-medium">{description}</p>
      </button>

      <Transition
        show={showTextArea}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <TextArea
          placeholder={placeholder}
          rows={4}
          error={error}
          {...register}
        />
      </Transition>
    </div>
  );
};

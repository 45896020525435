import {
  SectionHeader,
  Table,
  TableData,
  TableHead,
  TableHeader,
} from "~/common/components";
import { SymptomProgression } from "~/providers/utils/types/patientIntakeOverviewTypes";

interface PatientOverviewSymptomProgressionProps {
  symptomProgression: SymptomProgression[];
}

export const PatientOverviewSymptomProgression = ({
  symptomProgression,
}: PatientOverviewSymptomProgressionProps) => {
  return (
    <div className="flex flex-col gap-4 border-b-1.5 border-b-neutral-700 pb-9">
      <SectionHeader>Symptom progression over time</SectionHeader>
      <Table className="rounded-xl border">
        <TableHead>
          <tr>
            <TableHeader className="rounded-xl px-6">Symptom</TableHeader>
            <TableHeader className="px-6">Pattern</TableHeader>
          </tr>
        </TableHead>
        <tbody className="bg-white text-sm text-gray-70">
          {symptomProgression.map(({ symptom, progression }) => (
            <tr
              key={symptom}
              className="border-b border-gray-300 py-4 last:border-none"
            >
              <TableData className="rounded-b-xl py-4 first-letter:uppercase">
                {symptom}
              </TableData>
              <TableData className="py-4 first-letter:uppercase">
                {progression}
              </TableData>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";

import { EmptyState, LoadingState } from "~/common/components";
import { reviewInteractions } from "~/providers/api";
import {
  useGetCompletedCheckIn,
  useGetPatientFeeds,
  useIsTeamMemberStaff,
} from "~/providers/hooks";
import { PatientFeed } from "~/providers/utils/types/interactions";
import { InteractionDrawer } from "../patientInteractions/InteractionDrawer";
import { RenderActivityCard } from "./activityFeedCards";

interface ActivityFeedProps {
  patientId: string;
}

export const ActivityFeed = ({ patientId }: ActivityFeedProps) => {
  const { isStaff } = useIsTeamMemberStaff();
  const [selectedPatientInteractionId, setSelectedPatientInteractionId] =
    useState<string>();

  const { data: completedInteraction } = useGetCompletedCheckIn({
    patientId,
    checkInId: selectedPatientInteractionId,
  });

  const { data, loadMore, hasNextPage, isFetched, isLoading } =
    useGetPatientFeeds(patientId);

  const { mutate: reviewInteractionsMutation } = useMutation({
    mutationFn: () => reviewInteractions(patientId),
    onError: () => {
      toast.error("Something went wrong while reviewing the interactions.");
    },
  });

  useEffect(() => {
    if (isFetched) {
      !isStaff && reviewInteractionsMutation();
    }
  }, [reviewInteractionsMutation, isFetched, isStaff]);

  const totalInteractions = data?.pages[0]?.total;

  if (isLoading) {
    return <LoadingState className="h-full" />;
  }

  if (!totalInteractions || totalInteractions === 0) {
    return <EmptyState message="There is no recent activity to show." />;
  }

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasNextPage}
      loader={<LoadingState />}
      useWindow={false}
    >
      <div className="flex w-full flex-col gap-3 px-14 py-5">
        {data?.pages.flatMap((pageData) =>
          pageData.data.map((feedOption: PatientFeed) => (
            <RenderActivityCard
              key={feedOption.id}
              feedOption={feedOption}
              setSelectedPatientInteractionId={setSelectedPatientInteractionId}
            />
          )),
        )}
      </div>
      <InteractionDrawer
        selectedInteraction={completedInteraction}
        questions={completedInteraction?.checkin.questions}
        answers={completedInteraction?.answers}
        handleClose={() => setSelectedPatientInteractionId(undefined)}
      />
    </InfiniteScroll>
  );
};

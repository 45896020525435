import { Button } from "~/common/components";
import { NoteIcon } from "~/common/components/icons";
import { BUTTON_SIZES } from "~/common/constants";

interface NoteButtonProps {
  onClick: () => void;
}

export const NoteButton = ({ onClick }: NoteButtonProps) => (
  <Button
    size={BUTTON_SIZES.sm}
    className="absolute bottom-5 right-14 w-fit"
    variant="outline-black"
    onClick={onClick}
    left={<NoteIcon className="text-gray-700" />}
  >
    New note
  </Button>
);

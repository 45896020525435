import { generatePath, useNavigate } from "react-router-dom";

import { TableData } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { Patient, replaceUnderscores } from "~/common/utils";
import { ENGAGEMENT_TYPES } from "~/providers/constants/patientSearchConstants";
import { ROUTES } from "~/providers/router";
import { formatFullName } from "~/providers/utils";
import { PatientEngagementTag } from "./PatientEngagementTag";

interface PatientSearchRowTableDataProps {
  patient: Patient;
}

export const PatientSearchRowTableData = ({
  patient,
}: PatientSearchRowTableDataProps) => {
  const {
    id: patientId,
    name: firstName,
    last_name: lastName,
    archived_at: archivedAt,
    status,
  } = patient;

  const engagement = archivedAt
    ? ENGAGEMENT_TYPES.inactive
    : ENGAGEMENT_TYPES.active;

  const navigate = useNavigate();

  const tableRowRoute =
    status === PATIENT_STATUS.ongoing_care
      ? generatePath(ROUTES.activityFeed, { patientId })
      : generatePath(ROUTES.consultationOverview, { patientId });

  const handleTableRowClick = () => navigate(tableRowRoute);

  return (
    <tr
      className="transition hover:cursor-pointer hover:bg-gray-50"
      onClick={handleTableRowClick}
    >
      <TableData>
        <div className="flex items-center gap-4">
          {formatFullName(firstName, lastName)}
        </div>
      </TableData>
      <TableData className="first-letter:capitalize">
        {replaceUnderscores(patient.status)}
      </TableData>
      <TableData>
        <PatientEngagementTag engagement={engagement} />
      </TableData>
    </tr>
  );
};

export const BASE_SETTINGS_PATH = "/settings";
export const BASE_PATIENT = "/patient";
export const BASE_PATIENT_PROFILE = `${BASE_PATIENT}/:patientId`;
export const BASE_CONSULTATION_REQUEST = "/consultation-requests/:patientId";
export const CONSULTATION_PATH = "/consultation";
export const ONGOING_CONSULTATION_PATH = `${BASE_CONSULTATION_REQUEST}${CONSULTATION_PATH}`;
export const PATIENT_CONSULTATION_PATH = `${BASE_PATIENT_PROFILE}${CONSULTATION_PATH}`;

export const ROUTES = {
  login: "/login",
  signUp: "/sign-up",
  home: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/password-reset",
  activityFeed: `${BASE_PATIENT_PROFILE}/activity-feed`,
  medication: `${BASE_PATIENT_PROFILE}/medication`,
  ouraRing: `${BASE_PATIENT_PROFILE}/oura-ring`,
  orders: `${BASE_PATIENT_PROFILE}/orders`,
  notFound: "/not-found",
  internalError: "/internal-error",
  prescriptionRequest: "/prescription-requests",
  consultationRequests: "/consultation-requests",
  account: `${BASE_SETTINGS_PATH}/account`,
  paymentAndCarePlans: `${BASE_SETTINGS_PATH}/payment-and-care-plans`,
  patientConsultationInfo: `${BASE_CONSULTATION_REQUEST}/info`,
  patientInfo: `${BASE_PATIENT_PROFILE}/info`,
  consultationOverview: `${ONGOING_CONSULTATION_PATH}/overview`,
  consultationResponses: `${ONGOING_CONSULTATION_PATH}/responses`,
  consultationComplete: `${ONGOING_CONSULTATION_PATH}/complete`,
  patientOverview: `${PATIENT_CONSULTATION_PATH}/overview`,
  patientResponses: `${PATIENT_CONSULTATION_PATH}/responses`,
  patientComplete: `${PATIENT_CONSULTATION_PATH}/complete`,
  patientSearch: "/patient-search",
} as const;

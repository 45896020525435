import {
  EnjoymentIcon,
  FamilyIcon,
  HealthIcon,
  HopeIcon,
  MoneyIcon,
  MoodIcon,
  PostpartumIcon,
  RelationshipsIcon,
  SocialSupportIcon,
  WorkIcon,
} from "~/common/components";
import { FUNCTIONAL_DOMAIN_KEY } from "~/providers/utils/types/patientIntakeOverviewTypes";

export const functionalDomainIcon = {
  [FUNCTIONAL_DOMAIN_KEY.health]: <HealthIcon />,
  [FUNCTIONAL_DOMAIN_KEY.relationships]: <RelationshipsIcon />,
  [FUNCTIONAL_DOMAIN_KEY.social_support]: <SocialSupportIcon />,
  [FUNCTIONAL_DOMAIN_KEY.family]: <FamilyIcon />,
  [FUNCTIONAL_DOMAIN_KEY.hope]: <HopeIcon />,
  [FUNCTIONAL_DOMAIN_KEY.money]: <MoneyIcon />,
  [FUNCTIONAL_DOMAIN_KEY.enjoyment]: <EnjoymentIcon />,
  [FUNCTIONAL_DOMAIN_KEY.work]: <WorkIcon />,
  [FUNCTIONAL_DOMAIN_KEY.mood]: <MoodIcon />,
  [FUNCTIONAL_DOMAIN_KEY.post_partum]: <PostpartumIcon />,
};

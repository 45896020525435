import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export interface ThumbIconProps extends IconProps {
  isDown?: boolean;
}

export const ThumbIcon = ({
  className,
  isDown = false,
  ...props
}: ThumbIconProps) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge(className, isDown && "rotate-180 transform")}
    {...props}
  >
    <path
      d="M1.39722 6.43156L1.39722 11.0982M3.73055 6.15649V10.5268C3.73055 11.2632 4.3275 11.8601 5.06388 11.8601H10.324C10.9506 11.8601 11.4927 11.4238 11.6266 10.8117L12.5536 6.57402C12.6445 6.15826 12.3279 5.76489 11.9023 5.76489H9.39722C8.66084 5.76489 8.06388 5.16794 8.06388 4.43156V3.50976C8.06388 2.8194 7.78964 2.15731 7.30148 1.66915C7.00797 1.37564 6.51839 1.42824 6.29393 1.77741L3.94231 5.43548C3.80406 5.65055 3.73055 5.90082 3.73055 6.15649Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const providersWelcomeText =
  "we are on a transformative journey to ensure that every individual in need can easily connect with expert providers like yourself. Together, we are united in our shared objective of achieving wellness for all.";

export const OWNER_OPTIONS = {
  unassigned: "unassigned",
};

export const REGEX_SPLIT_DASH = /-(?!in\b)/;

export const TEXT_MAX_LENGTH = 2000;
export const LARGE_TEXT_LENGTH = 5000;
export const TEXT_NAME_MAX_LENGTH = 100;
export const SHORT_TEXT_MAX_LENGTH = 500;

import qs from "qs";

import { ORDER, ORDER_BY } from "~/common/constants";
import { UpdateNoteData } from "~/common/utils/types";
import {
  CHECKIN_STATUS,
  InteractionCreationTypes,
} from "~/common/utils/types/checkInTypes";
import { PatientData } from "../utils/types";
import {
  AddActivityFeedNoteData,
  AddBundleData,
  AddCheckInData,
  AddScheduleData,
  CollidingInteractions,
  GetCollidingOrdersData,
  Interaction,
  PatientCheckinRelatedData,
  PatientFeed,
  PatientSchedule,
} from "../utils/types/interactions";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatient = async (patient_id: string) => {
  const res = await api.get<PatientData>(
    `/v1/team-members/patients/${patient_id}`,
  );
  return res.data;
};

export const updateNote = async (data: UpdateNoteData, patient_id: string) => {
  const res = await api.put<PatientData>(
    `/v1/team-members/patients/${patient_id}/notes`,
    data,
  );
  return res.data;
};

export const getCompletedPatientInteractions = async (
  patient_id: string,
  page: number,
  params?: URLSearchParams,
) => {
  const queryParams = params ? Object.fromEntries(params) : {};
  const res = await api.get<PaginatedServiceResponse<Interaction[]>>(
    `/v1/team-members/patients/${patient_id}/interactions`,
    {
      params: {
        page,
        status: [CHECKIN_STATUS.completed, CHECKIN_STATUS.missed],
        with_answers: true,
        order: ORDER.descendant,
        ...queryParams,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
  );
  return res.data;
};

export const getPatientFeed = async (patient_id: string, page: number) => {
  const { data } = await api.get<PaginatedServiceResponse<PatientFeed[]>>(
    `/v1/team-members/${patient_id}/feeds`,
    {
      params: {
        page,
        order: ORDER.descendant,
      },
    },
  );

  return data;
};

export const reviewInteractions = async (patient_id: string) => {
  const res = await api.post<null>(
    `/v1/team-members/patients/${patient_id}/review-interactions`,
    null,
  );
  return res.data;
};

export const getPendingPatientInteractions = async (
  patient_id: string,
  creation_type: InteractionCreationTypes[],
  unique_by_bundle = false,
) => {
  const { data } = await api.get<PaginatedServiceResponse<Interaction[]>>(
    `/v1/team-members/patients/${patient_id}/interactions`,
    {
      params: {
        page_size: 100,
        page: 1,
        status: CHECKIN_STATUS.pending,
        with_answers: false,
        order: ORDER.ascendant,
        order_by: ORDER_BY.creationDate,
        creation_type: creation_type,
        unique_by_bundle,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
  );
  return data;
};

export const getPatientSchedules = async (patient_id: string) => {
  const { data } = await api.get<PaginatedServiceResponse<PatientSchedule[]>>(
    `/v1/team-members/patients/${patient_id}/checkin_schedules`,
    {
      params: {
        page_size: 100,
        page: 1,
        ignore_repeated: true,
      },
    },
  );
  return data;
};

export const getPatientCheckinRelatedData = async (
  patient_id: string,
  patient_checkin_id: string,
) => {
  const { data } = await api.get<PatientCheckinRelatedData>(
    `/v1/team-members/patients/${patient_id}/checkins/${patient_checkin_id}/related`,
  );
  return data;
};

export const deletePendingOrderAndRelated = async (
  patientId: string,
  orderId: string,
) => {
  const { data } = await api.delete<string>(
    `/v1/team-members/patients/${patientId}/checkins/${orderId}/related`,
  );
  return data;
};

export const deleteSchedule = async (patientId: string, scheduleId: string) => {
  const { data } = await api.delete<string>(
    `/v1/team-members/patients/${patientId}/checkin_schedules/${scheduleId}`,
  );
  return data;
};

export const addBundle = async ({
  patientId,
  bundleId,
  orderData,
}: AddBundleData) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/bundles/${bundleId}`,
    orderData,
  );
  return data;
};

export const addCheckIn = async ({
  patientId,
  checkInId,
  orderData,
}: AddCheckInData) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/checkins/${checkInId}`,
    orderData,
  );
  return data;
};

export const addSchedule = async ({
  patientId,
  checkInId,
  orderData,
}: AddScheduleData) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/schedules/${checkInId}`,
    orderData,
  );
  return data;
};

export const getCollidingOrders = async ({
  patientId,
  orderId,
  orderType,
}: GetCollidingOrdersData) => {
  const { data } = await api.get<CollidingInteractions[]>(
    `/v1/team-members/patients/${patientId}/colliding-interactions/${orderId}`,
    {
      params: {
        type: orderType,
      },
    },
  );
  return { data };
};

export const archiveLeadPatient = async (patientId: string) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/archive`,
    null,
  );

  return data;
};

export const createActivityFeedNote = async ({
  patientId,
  note,
}: AddActivityFeedNoteData) => {
  const data = await api.post<AddActivityFeedNoteData>(
    `/v1/team-members/patients/${patientId}/activity-notes`,
    { note },
  );
  return data;
};

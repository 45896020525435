import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UseFormSetError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { LOGIN_ERRORS } from "~/common/constants";
import { ErrorResponse, login } from "~/providers/api";
import { ROUTES } from "~/providers/router";
import { LogInFormValues } from "~/providers/screens";

export const useLogin = (setError: UseFormSetError<LogInFormValues>) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: loginMutation, isPending } = useMutation({
    mutationFn: login,
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 401) {
        setError("email", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
        setError("password", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
      } else {
        toast.error("Oops, there was an issue logging in, try again later!");
      }
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["getCurrentTeamMember"],
        exact: true,
      });
      queryClient.clear();
      navigate(ROUTES.home);
    },
  });

  return { loginMutation, isPending };
};

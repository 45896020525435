import { Option } from "~/common/utils";
import { CarePlanWithPatients } from "./types/carePlanTypes";

export const generateCarePlanOptions = (
  carePlans: CarePlanWithPatients[],
): Option[] =>
  carePlans.map(({ id, name, price }) => ({
    label: `${name} ($${price})`,
    value: id,
  }));

const INTAKE_QUESTION_CODES_SECTION_1 = {
  what_was_your_childhood_like: "what_was_your_childhood_like",
  when_you_think_of_the_memory_now: "when_you_think_of_the_memory_now",
  how_are_you_feeling_within_the_memory:
    "how_are_you_feeling_within_the_memory",
  what_is_the_first_memory: "what_is_the_first_memory",
};

const INTAKE_QUESTION_CODES_SECTION_2 = {
  symptoms_experience: "symptoms_experience",
  cut_down_alcohol_use: "cut_down_alcohol_use",
  mind_playing_tricks: "mind_playing_tricks",
  out_of_your_body: "out_of_your_body",
  first_noticed_symptoms: "first_noticed_symptoms",
  symptoms_reported: "symptoms_reported",
  depression_symptoms: "depression_symptoms",
  sleep_issues_symptoms: "sleep_issues_symptoms",
  attention_and_focus_symptoms: "attention_and_focus_symptoms",
  anxiety_symptoms: "anxiety_symptoms",
  panic_attack_symptoms: "panic_attack_symptoms",
  increased_spending_symptoms: "increased_spending_symptoms",
  appetite_changes_symptoms: "appetite_changes_symptoms",
  nightmare_symptoms: "nightmare_symptoms",
  flashbacks_symptoms: "flashbacks_symptoms",
  memory_problems_symptoms: "memory_problems_symptoms",
  preoccupation_weight_symptoms: "preoccupation_weight_symptoms",
  obsessions_symptoms: "obsessions_symptoms",
  compulsions_symptoms: "compulsions_symptoms",
  diminished_interest_symptoms: "diminished_interest_symptoms",
  irritability_symptoms: "irritability_symptoms",
  grand_ideas_symptoms: "grand_ideas_symptoms",
  suicidal_thoughts_symptoms: "suicidal_thoughts_symptoms",
  impulsivity_symptoms: "impulsivity_symptoms",
  other_symptoms: "other_symptoms",
  anything_else_symptoms: "anything_else_symptoms",
};

const INTAKE_QUESTION_CODES_SECTION_3 = {
  im_in_good_physical_health: "im_in_good_physical_health",
  i_have_no_major_health_worries: "i_have_no_major_health_worries",
  you_indicated_you_have_seen_a_therapist:
    "you_indicated_you_have_seen_a_therapist",
  you_indicated_you_have_seen_a_psychiatrist:
    "you_indicated_you_have_seen_a_psychiatrist",
  you_indicated_you_are_currently_taking_medications:
    "you_indicated_you_are_currently_taking_medications",
  do_you_have_any_past_history_of_genetic_testing:
    "do_you_have_any_past_history_of_genetic_testing",
  have_you_had_any_medical_or_psychiatric_hospitalizations:
    "have_you_had_any_medical_or_psychiatric_hospitalizations",
  medical_or_psychiatric_hospitalization_please_provide_more_details:
    "medical_or_psychiatric_hospitalization_please_provide_more_details",
  have_you_participated_in_any_other_outpatient_programs_or_treatments:
    "have_you_participated_in_any_other_outpatient_programs_or_treatments",
  do_you_have_any_known_allergies_to_medications:
    "do_you_have_any_known_allergies_to_medications",
  have_you_experienced_any_of_the_following_medical_issues:
    "have_you_experienced_any_of_the_following_medical_issues",
  have_you_had_any_abnormal_ekgs_in_the_past:
    "have_you_had_any_abnormal_ekgs_in_the_past",
  have_you_had_any_heart_murmurs: "have_you_had_any_heart_murmurs",
  have_you_ever_been_diagnosed_with_or_experienced_any_heart_arrhythmia:
    "have_you_ever_been_diagnosed_with_or_experienced_any_heart_arrhythmia",
  are_you_currently_pregnant: "are_you_currently_pregnant",
  when_is_your_expected_delivery_date: "when_is_your_expected_delivery_date",
  when_was_your_last_menstrual: "when_was_your_last_menstrual",
  undergone_any_surgeries: "undergone_any_surgeries",
  are_you_currently_experiencing_any_sexual_issues:
    "are_you_currently_experiencing_any_sexual_issues",
  any_other_medical_issues_we_should_know_about:
    "any_other_medical_issues_we_should_know_about",
  how_much_caffeine_do_you_consume_daily:
    "how_much_caffeine_do_you_consume_daily",
  do_you_smoke_cigarettes_or_use_tobacco_products:
    "do_you_smoke_cigarettes_or_use_tobacco_products",
  do_you_drink_alcohol: "do_you_drink_alcohol",
  how_much_do_you_drink_alcohol: "how_much_do_you_drink_alcohol",
  do_you_use_marijuana: "do_you_use_marijuana",
  how_much_marijuana_do_you_consume: "how_much_marijuana_do_you_consume",
  do_you_use_cocaine: "do_you_use_cocaine",
  do_you_use_ecstasy_mdma: "do_you_use_ecstasy_mdma",
  do_you_use_amphetamines: "do_you_use_amphetamines",
  do_you_use_opioids: "do_you_use_opioids",
  used_cocaine_ecstasy_mdma_opioids_pain_medicines_or_amphetamines:
    "used_cocaine_ecstasy_mdma_opioids_pain_medicines_or_amphetamines",
  do_you_use_mushrooms_psilocybin_lsd: "do_you_use_mushrooms_psilocybin_lsd",
  gamble_frequently: "gamble_frequently",
  find_yourself_preoccupied_with_sexual_behavior:
    "find_yourself_preoccupied_with_sexual_behavior",
};

const INTAKE_QUESTION_CODES_SECTION_4 = {
  most_important_family_members_in_your_life:
    "most_important_family_members_in_your_life",
  can_you_tell_me_about_your_mother: "can_you_tell_me_about_your_mother",
  can_you_tell_me_about_your_father: "can_you_tell_me_about_your_father",
  there_are_people_i_can_turn_to_if_i_need_help:
    "there_are_people_i_can_turn_to_if_i_need_help",
  i_feel_all_alone_in_the_world: "i_feel_all_alone_in_the_world",
  im_satisfied_with_my_family_life: "im_satisfied_with_my_family_life",
  my_relationship_with_my_partner_is_fulfilling:
    "my_relationship_with_my_partner_is_fulfilling",
  your_early_life: "your_early_life",
  what_is_your_parental_marital_status: "what_is_your_parental_marital_status",
  your_relationships_history: "your_relationships_history",
  do_you_have_any_children: "do_you_have_any_children",
  how_many_children_do_you_have_and_what_are_their_ages:
    "how_many_children_do_you_have_and_what_are_their_ages",
  are_you_having_relationship_stresses: "are_you_having_relationship_stresses",
  family_members_with_mood_disorders: "family_members_with_mood_disorders",
  family_members_with_anxiety_problems: "family_members_with_anxiety_problems",
  family_members_with_addiction_problems:
    "family_members_with_addiction_problems",
  family_history_of_adhd: "family_history_of_adhd",
  family_attempted_suicide_or_died_under_mysterious_circumstances:
    "family_attempted_complete_suicide_or_died_under_mysterious_circumstances",
  family_died_while_playing_sports: "family_died_while_playing_sports",
};

const INTAKE_QUESTION_CODES_SECTION_5 = {
  high_school_details: "high_school_details",
  college_details: "college_details",
  graduate_school_details: "graduate_school_details",
  what_did_teachers_say_about_you: "what_did_teachers_say_about_you",
  i_have_enough_money_to_meet_my_needs: "i_have_enough_money_to_meet_my_needs",
  i_dont_worry_about_finances: "i_dont_worry_about_finances",
  im_satisfied_with_my_job_or_school_situation:
    "im_satisfied_with_my_job_or_school_situation",
  my_career_or_school_is_rewarding_and_fulfilling:
    "my_career_or_school_is_rewarding_and_fulfilling",
  have_you_held_any_previous_jobs: "have_you_held_any_previous_jobs",
  jobs_you_have_held_in_the_past: "jobs_you_have_held_in_the_past",
  any_specific_work_stresses: "any_specific_work_stresses",
  best_qualities: "best_qualities",
  most_important_things_in_life: "most_important_things_in_life",
  i_find_it_easy_to_find_enjoyable_and_relaxing_activities:
    "i_find_it_easy_to_find_enjoyable_and_relaxing_activities",
  i_know_how_to_have_fun: "i_know_how_to_have_fun",
  i_feel_optimistic_about_my_future: "i_feel_optimistic_about_my_future",
  i_have_always_looked_forward_to_becoming_a_mother:
    "i_have_always_looked_forward_to_becoming_a_mother",
  motherhood_fills_me_with_joy: "motherhood_fills_me_with_joy",
};

const INTAKE_QUESTION_CODES_SECTION_6 = {
  association_instructions: "association_instructions",
  feel_about_partner: "feel_about_partner",
  feel_about_family: "feel_about_family",
  thought_about_finances: "thought_about_finances",
  feel_about_money: "feel_about_money",
  feel_about_work: "feel_about_work",
  faced_with_challenge: "faced_with_challenge",
  feel_lonely: "feel_lonely",
  thought_about_friends: "thought_about_friends",
  enjoyed_most: "enjoyed_most",
  to_relax: "to_relax",
  health_makes_feel: "health_makes_feel",
  thought_medical_condition: "thought_medical_condition",
  held_baby: "held_baby",
  being_mother: "being_mother_is",
  future_is: "future_is",
  most_of_the_time_feels: "most_of_the_time_feels",
  does_whatever: "does_whatever",
};

export const INTAKE_QUESTION_CODES = {
  ...INTAKE_QUESTION_CODES_SECTION_1,
  ...INTAKE_QUESTION_CODES_SECTION_2,
  ...INTAKE_QUESTION_CODES_SECTION_3,
  ...INTAKE_QUESTION_CODES_SECTION_4,
  ...INTAKE_QUESTION_CODES_SECTION_5,
  ...INTAKE_QUESTION_CODES_SECTION_6,
} as const;

export type IntakeQuestionCodes =
  (typeof INTAKE_QUESTION_CODES)[keyof typeof INTAKE_QUESTION_CODES];

const INTAKE_HAVE_YOU_EXPERIENCED_ANY_OF_THE_FOLLOWING_MEDICAL_ISSUES_MULTI_CHECK_VALUES =
  {
    asthma: "asthma",
    liver_issues: "liver_issues",
    seizures: "seizures",
    heart_history: "heart_history",
    diabetes: "diabetes",
    sleep_apnea: "sleep_apnea",
    excessive_snoring: "excessive_snoring",
    kidney_issues: "kidney_issues",
    head_traumas_losses_of_consciousness:
      "head_traumas_losses_of_consciousness",
    none_of_the_above: "none_of_the_above",
    other_medical_issue: "other",
  } as const;

const INTAKE_SYMPTOM_DETAIL_QUESTIONS_MULTI_CHECK_VALUES = {
  steady: "steady",
  getting_better: "getting_better",
  getting_worse: "getting_worse",
  intermittent_and_fully_better_between_episodes:
    "intermittent_and_fully_better_between_episodes",
  intermittent_and_not_fully_better_between_episodes:
    "intermittent_and_not_fully_better_between_episodes",
  less_frequent_episodes: "less_frequent_episodes",
  more_frequent_episodes: "more_frequent_episodes",
  unpredictably_variable: "unpredictably_variable",
} as const;

export const INTAKE_MULTI_CHECK_VALUES = {
  depression: "depression",
  sleep_issues: "sleep_issues",
  attention_and_focus_issues: "attention_and_focus_issues",
  panic_attacks: "panic_attacks",
  increased_spending_or_shopping: "increased_spending_or_shopping",
  appetite_changes: "appetite_changes",
  nightmares: "nightmares",
  flashbacks: "flashbacks",
  problems_with_memory: "problems_with_memory",
  preoccupation_with_weight_or_food: "preoccupation_with_weight_or_food",
  obsessions: "obsessions",
  compulsions: "compulsions",
  diminished_interest_in_previously_enjoyable_things:
    "diminished_interest_in_previously_enjoyable_things",
  irritability: "irritability",
  grand_ideas_feeling_on_top_of_the_world:
    "grand_ideas_feeling_on_top_of_the_world",
  suicidal_and_or_harmful_thoughts: "suicidal_and_or_harmful_thoughts",
  impulsivity: "impulsivity",
  none_of_these_apply: "none_of_these_apply",
  other: "other",
  hearing_voices_when_nobody_else_is_around:
    "hearing_voices_when_nobody_else_is_around",
  thinking_people_are_talking_to_you_or_about_you_when_nobody_else_is_around:
    "thinking_people_are_talking_to_you_or_about_you_when_nobody_else_is_around",
  thinking_people_you_may_see_or_that_may_be_on_tv_are_talking_about_you:
    "thinking_people_you_may_see_or_that_may_be_on_tv_are_talking_about_you",
  no: "no",
  ...INTAKE_SYMPTOM_DETAIL_QUESTIONS_MULTI_CHECK_VALUES,
  ...INTAKE_HAVE_YOU_EXPERIENCED_ANY_OF_THE_FOLLOWING_MEDICAL_ISSUES_MULTI_CHECK_VALUES,
} as const;

export const INTAKE_CODES = {
  WHERE_YOUR_STORY_BEGINS: "where_your_story_begins",
  WHAT_ARE_YOU_EXPERIENCING: "what_are_you_experiencing",
  YOUR_HEALTH_HISTORY_INTAKE: "your_health_history_intake",
  THE_PEOPLE_IN_YOUR_LIFE: "the_people_in_your_life",
  EDUCATION_WORK_LEISURE: "education_work_leisure",
  ASSOCIATIONS: "associations",
} as const;

export type IntakeCode = (typeof INTAKE_CODES)[keyof typeof INTAKE_CODES];

export const INTAKE_QUESTION_TYPES = {
  text: "text",
  radio: "radio",
  multi_check: "multi_check",
  radio_group: "radio_group",
  audio: "audio",
  symptoms_experienced_multi_select: "symptoms_experienced_multi_select",
  jobs_you_have_held: "jobs_you_have_held",
  radio_with_tertiary_education: "radio_with_tertiary_education",
  radio_with_text: "radio_with_text",
  radio_with_date: "radio_with_date",
  radio_with_optional_text: "radio_with_optional_text",
  radio_with_highschool: "radio_with_highschool",
  medical_or_psychiatric_hospitalization_please_provide_more_details:
    "medical_or_psychiatric_hospitalization_please_provide_more_details",
  you_indicated_you_have_seen_a_therapist:
    "you_indicated_you_have_seen_a_therapist",
  you_indicated_taking_medications: "you_indicated_taking_medications",
  yes_no_na_with_audio_on_yes: "yes_no_na_with_audio_on_yes",
  date: "date",
  instructions: "instructions",
} as const;

export type IntakeQuestionTypes =
  (typeof INTAKE_QUESTION_TYPES)[keyof typeof INTAKE_QUESTION_TYPES];

export const TOTAL_AMOUNT_OF_INTAKES = 6;

export const INTAKE_FORM_TYPE = {
  question: "question",
  conditional: "conditional",
};

export type IntakeFormType =
  (typeof INTAKE_FORM_TYPE)[keyof typeof INTAKE_FORM_TYPE];

export const YES_NO_TYPES = {
  yes: "yes",
  no: "no",
} as const;

export const YES_NO_NA_TYPES = {
  ...YES_NO_TYPES,
  na: "n/a",
} as const;

export type YesNoNaValueType =
  (typeof YES_NO_NA_TYPES)[keyof typeof YES_NO_NA_TYPES];

export const TERTIARY_EDUCATION_TYPE = {
  college: "college",
  graduate_school: "graduate_school",
} as const;

export type TertiaryEducationTypeTypes = keyof typeof TERTIARY_EDUCATION_TYPE;
export const YES_NO_NOT_SURE_TYPES = {
  ...YES_NO_TYPES,
  notSure: "not_sure",
} as const;

export const INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES = {
  steady: "steady",
  getting_better: "getting_better",
  getting_worse: "getting_worse",
  intermittent_and_fully_better_between_episodes:
    "intermittent_and_fully_better_between_episodes",
  intermittent_and_not_fully_better_between_episodes:
    "intermittent_and_not_fully_better_between_episodes",
  less_frequent_episodes: "less_frequent_episodes",
  more_frequent_episodes: "more_frequent_episodes",
  unpredictably_variable: "unpredictably_variable",
} as const;

export type IntakeSymptomDetailQuestionType =
  keyof typeof INTAKE_SYMPTOM_DETAIL_QUESTIONS_RADIO_GROUP_VALUES;

import { useState } from "react";

import { CardInputsContainer } from "~/common/components";
import { useListCarePlans } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/myCarePlans/useListCarePlans";
import { CarePlanWithPatients } from "~/providers/utils/types/carePlanTypes";
import { AddNewCarePlanCard } from "./AddNewCarePlanCard";
import { CarePlanCard } from "./CarePlanCard";
import { DeleteCarePlanModal } from "./modals/DeleteCarePlanModal";
import { ListCarePlanMembersModal } from "./modals/ListCarePlanMembersModal";
import { UpsertCarePlanModal } from "./modals/UpsertCarePlanModal";

export const ACTION_OPTIONS = {
  upsert: "upsert",
  delete: "delete",
  listUsers: "listUsers",
} as const;

export interface ModalToOpen {
  action?: keyof typeof ACTION_OPTIONS;
  carePlan?: CarePlanWithPatients;
}
export const CarePlans = () => {
  const [modalToOpen, setModalToOpen] = useState<ModalToOpen>();
  const { action, carePlan } = { ...modalToOpen };

  const { data: carePlans } = useListCarePlans({ withPatients: true });

  const onModalClose = () => {
    setModalToOpen({ ...modalToOpen, action: undefined });
  };

  return (
    <>
      <CardInputsContainer title="Care plans">
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          {carePlans?.map((carePlan) => (
            <CarePlanCard
              carePlan={carePlan}
              key={carePlan.id}
              setModalToOpen={setModalToOpen}
            />
          ))}

          <AddNewCarePlanCard setModalToOpen={setModalToOpen} />
        </div>
      </CardInputsContainer>
      <DeleteCarePlanModal
        carePlan={carePlan}
        show={action === ACTION_OPTIONS.delete}
        onClose={onModalClose}
      />
      <ListCarePlanMembersModal
        carePlan={carePlan}
        show={action === ACTION_OPTIONS.listUsers}
        onClose={onModalClose}
      />
      <UpsertCarePlanModal
        carePlan={carePlan}
        show={action === ACTION_OPTIONS.upsert}
        onClose={onModalClose}
      />
    </>
  );
};

import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { ConsultationCompletionDataType } from "~/providers/utils";
import {
  CompletedMultiCheckAnswer,
  MultiCheckQuestionData,
} from "~/providers/utils/mentalStatusExaminations/interfaces";
import { mentalStatusExaminationsQuestions } from "~/providers/utils/mentalStatusExaminations/mentalStatusExaminationsQuestions";
import { MentalStatusExaminationsQuestions } from "~/providers/utils/mentalStatusExaminations/questionData";
import { MentalStatusExaminationForm } from "./MentalStatusExaminationForm";

interface MentalStatusExaminationProps {
  getValues: UseFormGetValues<ConsultationCompletionDataType>;
  register: UseFormRegister<ConsultationCompletionDataType>;
  errors: FieldErrors<ConsultationCompletionDataType>;
  control: Control<ConsultationCompletionDataType>;
}

export const MentalStatusExamination = ({
  getValues,
  register,
  errors,
  control,
}: MentalStatusExaminationProps) => {
  return (
    <div className="grid gap-8 md:grid-cols-3">
      {mentalStatusExaminationsQuestions.map((question) => {
        const completedAnswer = getValues(
          `data.${question.key as MentalStatusExaminationsQuestions}`,
        );

        return (
          <MentalStatusExaminationForm
            key={question.key}
            question={question as MultiCheckQuestionData}
            completedAnswer={completedAnswer as CompletedMultiCheckAnswer}
            getValues={getValues}
            register={register}
            errors={errors}
            control={control}
          />
        );
      })}
    </div>
  );
};

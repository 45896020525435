import {
  RegisterKeys,
  SymptomBaseForm,
  symptomsBaseForm,
} from "~/patients/utils/consultations/intake";

export const getFormOfSelectedSymptom = (
  selectedSymptom: RegisterKeys,
): SymptomBaseForm | undefined =>
  symptomsBaseForm.find(
    (symptom) => symptom.value === `${selectedSymptom}.toggled`,
  );

export const getSymptomLabel = (
  symptomsForm: SymptomBaseForm[],
  symptom: RegisterKeys,
  key: string,
) =>
  symptomsForm.find((form) => form.value === `${symptom}.${key}.toggled`)
    ?.label;

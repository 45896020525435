import { PropsWithChildren } from "react";

import { SectionHeader } from "~/common/components";
import { Button } from "~/common/components/ui/button";
import { useIsTeamMemberStaff } from "~/providers/hooks";

interface OrdersLayoutProps extends PropsWithChildren {
  handleAddOrder?: () => void;
}

export const OrdersLayout = ({
  handleAddOrder,
  children,
}: OrdersLayoutProps) => {
  const { isStaff } = useIsTeamMemberStaff();

  return (
    <div className="flex h-full flex-col items-center px-14 py-8 text-gray-70">
      <div className="flex w-full flex-row items-end justify-between">
        <div className="flex items-center justify-center gap-2">
          <SectionHeader>Orders</SectionHeader>
        </div>
        {!isStaff && (
          <Button className="w-fit" size="sm" onClick={handleAddOrder}>
            Add new order
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};

import {
  ScreeningCode,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";
import { ScoreData } from "~/providers/utils/types/patientOverviewTypes";

interface SelfReportedScoresCardProps {
  code: ScreeningCode;
  scoreData: ScoreData;
}

export const SelfReportedScoresCard = ({
  code,
  scoreData: { score, label },
}: SelfReportedScoresCardProps) => {
  return (
    <div className="flex h-48 w-full flex-col items-center gap-3 rounded-md border-1.5 px-16 py-6">
      <h3 className="text-base font-semibold">{screeningSectionTitle[code]}</h3>
      <div className="flex items-center justify-items-center rounded-md bg-primary-50 px-5 py-3">
        <span className="text-4xl text-gray-70">{score}</span>
      </div>
      <span className="text-center font-light text-gray-70">{label}</span>
    </div>
  );
};

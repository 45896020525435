import { Fragment, ReactElement } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { ChevronIcon } from "~/common/components";
import TickIcon from "~/common/components/icons/TickIcon";
import { DIRECTIONS } from "~/common/constants";
import { Directions, Option } from "~/common/utils";

interface ListBoxContentProps {
  options: Option[];
  hasError: boolean;
  value: string;
  buttonClassName?: string;
  controllerOnChange?: (value: string) => void;
  direction?: Directions;
  icon?: ReactElement;
  isDisabled?: boolean;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?: (value: string) => void;
  optionsClassName?: string;
  placeholder?: string;
  checkSelectedOption?: boolean;
  onClick?: (event?: React.MouseEvent) => void;
  optionsOnClick?: (event?: React.MouseEvent) => void;
  buttonContent?: () => React.JSX.Element;
}

const ListBoxContent = ({
  buttonClassName,
  controllerOnChange,
  direction,
  hasError = false,
  icon,
  isDisabled,
  labelClassName,
  noPadding = false,
  onChange,
  options,
  optionsClassName,
  placeholder,
  value,
  onClick,
  optionsOnClick,
  buttonContent,
  checkSelectedOption = false,
}: ListBoxContentProps) => {
  const isPlaceholder = (v: string) =>
    (!v && !isDisabled) || !options.find((o) => o.value === v)?.label;

  const selectedValue =
    options.find((o) => o.value === value)?.label ?? placeholder;

  return (
    <Listbox
      defaultValue={value}
      disabled={isDisabled}
      onChange={(val) => {
        if (onChange) {
          onChange(val ?? "");
        }
        if (controllerOnChange) {
          controllerOnChange(val);
        }
      }}
    >
      {({ open }) => (
        <div className="relative size-full">
          <Listbox.Button
            onClick={onClick}
            className={twMerge(
              "relative flex w-full cursor-pointer items-center justify-between rounded-md border border-gray-20 bg-neutral-25 py-3 text-left text-sm text-gray-700",
              icon ? "gap-2 px-3" : "h-auto pl-3 pr-10",
              noPadding ? "pr-3" : "pr-5",
              hasError && "border-red-500",
              isDisabled && "bg-gray-100",
              !!buttonContent && "border-none bg-transparent pl-0",
              buttonClassName,
            )}
          >
            {buttonContent ?? (
              <>
                <p
                  className={twMerge(
                    "first-letter:uppercase",
                    labelClassName,
                    "block truncate",
                    isDisabled && "text-gray-400",
                    isPlaceholder(value) && "text-gray-500",
                  )}
                >
                  {value ? selectedValue : placeholder}
                </p>
                <div
                  className={twMerge(
                    "pointer-events-none inset-y-0 flex items-center hover:opacity-75 disabled:opacity-25",
                    icon && "absolute right-0",
                    noPadding && "pr-5",
                  )}
                >
                  {icon ?? (
                    <ChevronIcon
                      direction={open ? DIRECTIONS.top : DIRECTIONS.down}
                      className="text-light-gray h-3 w-3 transition-all duration-300"
                    />
                  )}
                </div>
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={twMerge(
                "scrollbar-hide absolute z-10 mt-2 max-h-60 w-full origin-top-right overflow-auto rounded-md bg-slate-50 py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                optionsClassName,
                direction === "left" && "left-0",
                direction === "right" && "right-0",
                direction === "top" && "-top-2 -translate-y-full transform",
              )}
            >
              {options.map((option) => (
                <Listbox.Option
                  onClick={optionsOnClick}
                  key={option.value}
                  className={twMerge(
                    "relative flex w-full cursor-pointer select-none justify-between p-3 font-light text-gray-70 hover:bg-blue-50",
                    option.disabled &&
                      "hover:bg-neutral cursor-default bg-neutral-10 text-neutral-500",
                    value === option.value && "bg-blue-100",
                  )}
                  value={option.value}
                  disabled={option.disabled}
                >
                  <p className="first-letter:uppercase">{option.label}</p>
                  {checkSelectedOption && value === option.value && (
                    <TickIcon />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default ListBoxContent;

import {
  RegisterKeys,
  SymptomBaseForm,
  SymptomsFormTypes,
} from "~/patients/utils/consultations/intake";
import { getSymptomLabel } from "./getFormInformationOfSelectedSymptom";

export const symptomsCheckedToString = (
  symptom: RegisterKeys,
  symptomsCompletedAnswer: SymptomsFormTypes,
  symptomsForm: SymptomBaseForm[],
): string =>
  Object.entries(symptomsCompletedAnswer)
    .filter(([_, v]) => {
      const value = v as SymptomsFormTypes;
      return value?.toggled;
    })
    .map(([key, _]) => {
      if (key === "harming_yourself_through_violence") {
        const extraData = Object.entries(symptomsCompletedAnswer)
          .filter(([_, v]) => {
            const value = v as SymptomsFormTypes;
            return value?.toggled;
          })
          .map(([key, _]) => getSymptomLabel(symptomsForm, symptom, key))
          .join(", ");

        return `${getSymptomLabel(symptomsForm, symptom, key)}: ${extraData}`;
      }
      return getSymptomLabel(symptomsForm, symptom, key);
    })
    .join(", ");

import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { StarsIcon } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { SectionMenuOption } from "~/common/types";
import { PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";
import { patientCompletedIntake } from "./patientStatusHistory";

export const getConsultationMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
  isStaff: boolean,
): SectionMenuOption[] => {
  const actualPath = window.location.pathname;
  const isPatientActive = patientStatus === PATIENT_STATUS.ongoing_care;

  const showCompleteAndSignForProvider =
    !isStaff &&
    (patientCompletedIntake(patientStatus) ||
      patientStatus === PATIENT_STATUS.ongoing_care);

  const showCompleteAndSignForStaff =
    isStaff &&
    (patientStatus === PATIENT_STATUS.ongoing_care ||
      patientStatus === PATIENT_STATUS.consultation_completed);

  const overviewRoute = isPatientActive
    ? ROUTES.patientOverview
    : ROUTES.consultationOverview;

  const rawResponsesRoute = isPatientActive
    ? ROUTES.patientResponses
    : ROUTES.consultationResponses;

  const completeRoute = isPatientActive
    ? ROUTES.patientComplete
    : ROUTES.consultationComplete;

  return [
    {
      label: "Overview",
      onClick: () =>
        navigate(
          generatePath(overviewRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(overviewRoute, actualPath),
      enable: true,
      icon: <StarsIcon />,
    },
    {
      label: "Raw responses",
      onClick: () =>
        navigate(
          generatePath(rawResponsesRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(rawResponsesRoute, actualPath),
      enable: true,
    },
    {
      label: "Complete and sign",
      onClick: () =>
        navigate(
          generatePath(completeRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(completeRoute, actualPath),
      enable: showCompleteAndSignForStaff || showCompleteAndSignForProvider,
    },
  ];
};

import { EmptyTableState } from "~/common/components";
import { Patient } from "~/common/utils";
import { LoadingPatientsTable } from "../LoadingPatientsTable";
import { PatientSearchRowTableData } from "./PatientSearchRowTableData";

interface PatientSearchTableBodyProps {
  patients: Patient[];
  isLoading?: boolean;
}

export const PatientSearchTableBody = ({
  patients,
  isLoading,
}: PatientSearchTableBodyProps) => {
  const noPatients = !patients.length;

  if (isLoading) return <LoadingPatientsTable length={8} />;

  return (
    <tbody className="max-h-[38rem] text-sm text-gray-70">
      {noPatients ? (
        <EmptyTableState colSpan={3} message="No patients found" />
      ) : (
        patients.map((patient) => (
          <PatientSearchRowTableData key={patient.id} patient={patient} />
        ))
      )}
    </tbody>
  );
};

import { PatientIntakeConsultationSummary } from "~/providers/utils/types/patientIntakeOverviewTypes";
import {
  PatientScreeningConsultationSummary,
  SUMMARY_TYPE,
} from "~/providers/utils/types/patientOverviewTypes";
import { isFeedbackLiked } from "./isFeedbackLiked";

interface GetConsultationSummaryDataProps {
  screeningSummary: PatientScreeningConsultationSummary;
  intakeSummary?: PatientIntakeConsultationSummary;
}

export const getConsultationSummaryData = ({
  screeningSummary,
  intakeSummary,
}: GetConsultationSummaryDataProps) => {
  const {
    id: screeningSummaryId,
    feedbacks: screeningFeedbacks,
    data: {
      long_summary: longSummaryScreening,
      significant_clinical_dimensions: significantClinicalDimensions,
      medications,
      scores,
    },
  } = screeningSummary;

  const {
    id: intakeSummaryId,
    feedbacks: intakeFeedbacks,
    data: intakeSummaryData,
  } = { ...intakeSummary };

  const {
    long_summary: longSummaryIntake,
    functional_domains: functionalDomains,
    symptom_progression: symptomProgression,
    treatment_recommendations: treatmentRecommendations,
  } = { ...intakeSummaryData };

  const isSummaryLiked = intakeSummaryId
    ? isFeedbackLiked(SUMMARY_TYPE.intake, intakeFeedbacks)
    : isFeedbackLiked(SUMMARY_TYPE.screening, screeningFeedbacks);

  const isTreatmentRecommendationsLiked = isFeedbackLiked(
    SUMMARY_TYPE.treatment_recommendations,
    intakeFeedbacks,
  );

  const longSummary = longSummaryIntake ?? longSummaryScreening;

  return {
    screeningSummaryId,
    intakeSummaryId,
    isSummaryLiked,
    isTreatmentRecommendationsLiked,
    longSummary,
    significantClinicalDimensions,
    medications,
    scores,
    functionalDomains,
    symptomProgression,
    treatmentRecommendations,
  };
};

import { z } from "zod";

import { TEXT_MAX_LENGTH, TEXT_NAME_MAX_LENGTH } from "../constants";

export const baseCarePlan = {
  name: "",
  description: "",
  price: "",
  default: false,
};

export const paymentSettingsSchema = z.object({
  secret_key: z
    .string()
    .min(1, "Secret key is required.")
    .startsWith("rk_", { message: "Secret key must start with 'rk_'." }),
  publishable_key: z.string().min(1, "Publishable key is required."),
  default_consultation_fee: z
    .number({ message: "Please enter a valid number (e.g., 25.6)." })
    .min(0, "Consultation fee must be greater or equal than 0.")
    .nullable(),
});

export const upsertCarePlan = z.object({
  name: z
    .string()
    .min(1, "Care Plan name is required.")
    .max(
      TEXT_NAME_MAX_LENGTH,
      `Maximum ${TEXT_NAME_MAX_LENGTH} characters allowed`,
    ),
  description: z
    .string()
    .min(1, "Care Plan description is required.")
    .max(TEXT_MAX_LENGTH, `Maximum ${TEXT_MAX_LENGTH} characters allowed`)
    .optional(),
  price: z.string().regex(/^\d+(\.\d{1,2})?$/, "Invalid monthly fee"),
  default: z.boolean().optional(),
});

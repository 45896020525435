import { Table, TableData } from "~/common/components/table";
import { TABLE_DATA_VARIANTS } from "~/common/constants";
import { PatientPharmacy } from "~/common/types/pharmacies";
import { formatMedicationNameWithStrength, TeamMember } from "~/common/utils";
import { formatPharmacyToString } from "~/common/utils/formatPharmacy/formatPharmacyToString";
import { formatDate, ProviderMedication } from "~/providers/utils";
import { formatUserName } from "~/providers/utils/medications/formatUserName";
import { RenderRequiredText } from "./RenderRequiredText";

interface ScriptDetailsTableProps {
  pre_authorization_request: boolean;
  patient_medication: ProviderMedication;
  patient_pharmacy: PatientPharmacy;
  created_at: string;
  closed_at?: string;
  closed_by?: TeamMember;
}

export const ScriptRequestDetails = ({
  pre_authorization_request,
  closed_at,
  closed_by,
  created_at,
  patient_medication,
  patient_pharmacy,
}: ScriptDetailsTableProps) => {
  const { name, last_name } = { ...closed_by };

  return (
    <div className="pb-2 text-gray-70">
      <h2 className="text-lg font-medium leading-6 first-letter:uppercase">
        Request details
      </h2>
      <Table className="border-spacing-2">
        <tbody>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              Provider approval
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              <RenderRequiredText
                status={!!patient_medication?.paused_scripts_at}
                alertIcon
              />
            </TableData>
          </tr>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              Pre-auth
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              <RenderRequiredText status={pre_authorization_request} />
            </TableData>
          </tr>
          <tr>
            <TableData className="w-1/3" variant={TABLE_DATA_VARIANTS.outlined}>
              Medication
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              {patient_medication
                ? formatMedicationNameWithStrength(
                    patient_medication.medication,
                    patient_medication.patient_medications_details[0]
                      .medication_strength,
                  )
                : "-"}
            </TableData>
          </tr>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined} className="flex">
              Pharmacy
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              {patient_pharmacy
                ? formatPharmacyToString(patient_pharmacy.pharmacy)
                : "-"}
            </TableData>
          </tr>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              Created on
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              {created_at ? formatDate(created_at) : "-"}
            </TableData>
          </tr>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              Closed on
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              {closed_at ? formatDate(closed_at) : "-"}
            </TableData>
          </tr>
          <tr>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              Closed by
            </TableData>
            <TableData variant={TABLE_DATA_VARIANTS.outlined}>
              {name && last_name ? formatUserName(name, last_name) : "-"}{" "}
            </TableData>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

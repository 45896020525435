import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { ORDER, ORDER_BY, SCRIPTS_PAGE_SIZE } from "~/common/constants";
import { SCRIPT_FILTERS_VALUES } from "~/providers/constants";
import { useGetScriptsList, useGetTeamMembersList } from "~/providers/hooks";
import { generateTeamMemberOptions } from "~/providers/utils";
import { generatePrescriptionOwnerOptions } from "~/providers/utils/generatePrescriptionOwnerOptions";
import Pagination from "../Pagination";
import { RequestsLayout } from "../RequestsLayout";
import { ScriptDetailsDrawer } from "../ScriptDetails/ScriptDetailsDrawer";
import { PrescriptionRequestFilters } from "./PrescriptionRequestFilters";
import { PrescriptionRequestTable } from "./PrescriptionRequestTable";

export const PrescriptionRequest = () => {
  const [params] = useSearchParams({
    page_size: SCRIPTS_PAGE_SIZE,
    order: ORDER.descendant,
    order_by: ORDER_BY.creationDate,
  });

  const debouncedParams = useDebounce(params, 600);

  const {
    data: prescriptionRequests,
    isLoading,
    isFetched,
  } = useGetScriptsList(debouncedParams);

  const noFilterApplied = !SCRIPT_FILTERS_VALUES.some((filter) =>
    params.has(filter),
  );
  const noPrescriptionRequestsAssigned =
    noFilterApplied && !prescriptionRequests?.data.length;
  const { data: teamMembers } = useGetTeamMembersList();
  const teamMemberOptions = generateTeamMemberOptions(teamMembers);
  const teamMemberGroup = generatePrescriptionOwnerOptions(
    "Prescription owner",
    teamMemberOptions,
  );

  return (
    <RequestsLayout>
      <PrescriptionRequestFilters
        isLoading={isLoading}
        disableFilters={noPrescriptionRequestsAssigned}
        ownersOptions={teamMemberGroup}
      />
      <div className="flex size-full flex-col items-end justify-between gap-3 overflow-auto">
        {!isLoading && (
          <PrescriptionRequestTable
            noPrescriptionRequestsAssigned={noPrescriptionRequestsAssigned}
            prescriptionRequests={prescriptionRequests?.data}
            isLoading={isLoading}
            isFetched={isFetched}
            ownersOptions={teamMemberOptions}
          />
        )}
        {!!prescriptionRequests?.total_pages &&
          prescriptionRequests.total_pages > 1 && (
            <Pagination
              totalItems={prescriptionRequests.total}
              currentPage={prescriptionRequests.page}
              totalPages={prescriptionRequests.total_pages}
            />
          )}
      </div>
      <ScriptDetailsDrawer />
    </RequestsLayout>
  );
};

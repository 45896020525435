import { intakeQuestionDataByCodes } from "~/common/utils/consultations/intake";
import { screeningQuestionDataByCodes } from "~/common/utils/consultations/screening";
import { renderQuestionTitle } from "~/common/utils/renderQuestionTitle";
import { PronounsOptions } from "~/patients";
import { SymptomsListFormValues } from "~/patients/utils/consultations/intake";
import { formatRawResponse } from "~/providers/utils/consultationRequests/formatRawResponses";
import {
  AudioResponse,
  IntakeAnswer,
  IntakeQuestionType,
  RawResponsesType,
  RESPONSE_QUESTION_TYPES,
  ScreeningAnswer,
  ScreeningQuestionType,
  YesNoWithAudioResponse,
} from "~/providers/utils/types/rawResponsesTypes";
import { RawResponseLayout } from "./RawResponseLayout";
import { AudioAnswer } from "./rawResponses/AudioAnswer";
import { SymptomsAnswer } from "./rawResponses/SymptomsAnswer";
import { TextAnswer } from "./rawResponses/TextAnswer";
import { YesNoWithAudioAnswer } from "./rawResponses/YesNoWithAudioAnswer";

interface RawResponsesRenderProps {
  answer: ScreeningAnswer | IntakeAnswer;
  isIntake: boolean;
  pronouns?: PronounsOptions;
}

export const RawResponsesRender = ({
  answer: answerObject,
  isIntake,
  pronouns,
}: RawResponsesRenderProps) => {
  return Object.entries(answerObject).map(([questionCode, answer]) => {
    const isAnswersArray = Array.isArray(answer) && answer.length > 0;
    const questionData = isIntake
      ? intakeQuestionDataByCodes[questionCode as IntakeQuestionType]
      : screeningQuestionDataByCodes[questionCode as ScreeningQuestionType];

    const renderAnswer = (answerObject: ScreeningAnswer | IntakeAnswer) => {
      if (answerObject.type === RESPONSE_QUESTION_TYPES.audio)
        return (
          <AudioAnswer
            questionCode={questionCode}
            answers={answer as AudioResponse[]}
          />
        );

      if (
        answerObject.type ===
        RESPONSE_QUESTION_TYPES.yes_no_na_with_audio_on_yes
      )
        return (
          <YesNoWithAudioAnswer
            questionCode={questionCode}
            answers={answer as YesNoWithAudioResponse[]}
          />
        );

      return (
        <TextAnswer
          questionCode={questionCode}
          answers={formatRawResponse(
            answerObject.type,
            answer as RawResponsesType,
            questionCode,
          )}
        />
      );
    };

    if (
      isIntake &&
      answerObject.type ===
        RESPONSE_QUESTION_TYPES.symptoms_experienced_multi_select
    ) {
      return (
        <SymptomsAnswer
          baseQuestion={renderQuestionTitle(questionData?.title, pronouns)}
          key={questionCode}
          answers={answer as SymptomsListFormValues[]}
        />
      );
    }

    return (
      <RawResponseLayout
        question={renderQuestionTitle(questionData?.title, pronouns)}
        key={questionCode}
      >
        {isAnswersArray && renderAnswer(answerObject)}
      </RawResponseLayout>
    );
  });
};

import {
  SectionHeader,
  Table,
  TableData,
  TableHead,
  TableHeader,
} from "~/common/components";
import { MedicationsData } from "~/providers/utils/types/patientOverviewTypes";

interface PatientOverviewCurrentMedicationsProps {
  medications: MedicationsData[];
}

export const PatientOverviewCurrentMedications = ({
  medications,
}: PatientOverviewCurrentMedicationsProps) => {
  return (
    <div className="flex flex-col gap-4 border-b-1.5 border-b-neutral-700 pb-9">
      <SectionHeader>Current medications</SectionHeader>
      <Table className="rounded-xl border">
        <TableHead>
          <tr>
            <TableHeader className="rounded-xl px-6">
              Medication name
            </TableHeader>
            <TableHeader className="px-6">Dose</TableHeader>
            <TableHeader className="rounded-xl px-6">Frequency</TableHeader>
          </tr>
        </TableHead>
        <tbody className="bg-white text-sm text-gray-70">
          {medications.map(({ name, dose, frequency }) => (
            <tr
              key={name}
              className="border-b border-gray-300 py-4 last:border-none"
            >
              <TableData className="rounded-b-xl py-4">{name}</TableData>
              <TableData className="py-4">{dose}</TableData>
              <TableData className="rounded-b-xl py-4">{frequency}</TableData>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

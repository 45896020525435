import { toast } from "react-toastify";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import { LEAD_PATIENT_STATUS } from "~/common/constants";
import { useChangePatientStatus } from "~/providers/hooks/queries/useChangePatientStatus";

interface ReferPatientModalProps {
  handleCloseModal: () => void;
  patientId: string;
  isOpenModal: boolean;
}

export const ReferPatientModal = ({
  handleCloseModal,
  patientId,
  isOpenModal,
}: ReferPatientModalProps) => {
  const onSuccess = () => {
    handleCloseModal();
    toast.success("Patient referred successfully!");
  };
  const { changePatientStatusMutation } = useChangePatientStatus(onSuccess);
  const handleSubmit = () =>
    changePatientStatusMutation({
      patientId,
      status: LEAD_PATIENT_STATUS.referred_after_screening,
    });

  return (
    <Modal
      className="h-fit"
      titleClassName="text-2xl font-medium w-2/3 text-gray-70"
      title="Refer"
      description="Are you sure you want to refer this patient and end the consultation?"
      show={isOpenModal}
      onClose={handleCloseModal}
    >
      <div>
        <div className="flex h-full w-full flex-col gap-3 text-gray-70">
          <div className="flex flex-col gap-3 self-end">
            <CancelSubmitButtons
              cancelClassName="text-sm w-auto text-gray-70 border-gray-70 px-6"
              submitClassName="px-8"
              handleCancel={handleCloseModal}
              cancelButtonLabel="Cancel"
              handleSubmit={handleSubmit}
              submitButtonLabel="Refer"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

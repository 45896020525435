import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { FlagIcon } from "~/common/components/icons";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import {
  MedicationNotes,
  PatientMedicationDetail,
  PatientMedicationStatus,
} from "~/common/types";
import { formatDate } from "~/providers/utils";
import { MedicationMessage } from "../MedicationMessage";
import { MedicationsTable } from "../MedicationsTable";

interface MedicationCardDetailsProps extends PropsWithChildren {
  status: PatientMedicationStatus;
  discontinuationTitle?: string;
  internalNote?: string;
  patientNote?: string;
  patientMedicationsDetails: PatientMedicationDetail[];
  pausedScriptDate?: string;
  pausedScriptNote?: MedicationNotes;
  medicationName: string;
}

export const MedicationCardDetails = ({
  status,
  discontinuationTitle,
  internalNote,
  patientNote,
  patientMedicationsDetails,
  pausedScriptDate,
  pausedScriptNote,
  medicationName,
}: MedicationCardDetailsProps) => {
  const scriptStatus = pausedScriptDate
    ? "Script paused - "
    : "Script unpaused - ";
  return (
    <div
      className={twMerge(
        "flex flex-col gap-4 border-t border-primary-200 pt-4",
        status === PATIENT_MEDICATION_STATUS.discontinued &&
          "border-neutral-700",
      )}
    >
      {!!discontinuationTitle && (
        <div className="flex flex-col gap-2">
          <p className="text-base font-medium">{discontinuationTitle}</p>
          <div
            className={twMerge(
              "flex gap-4 border-b border-primary-200 pb-4",
              status === PATIENT_MEDICATION_STATUS.discontinued &&
                "border-neutral-700",
            )}
          >
            {internalNote && (
              <MedicationMessage title="Internal note">
                {internalNote}
              </MedicationMessage>
            )}
            {patientNote && (
              <MedicationMessage title="Message to patient">
                {patientNote}
              </MedicationMessage>
            )}
          </div>
        </div>
      )}
      {pausedScriptNote && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            {pausedScriptDate && <FlagIcon />}
            <p className="text-base font-medium">
              {scriptStatus}
              <span className="font-normal">
                {formatDate(pausedScriptDate ?? pausedScriptNote.created_at)}
              </span>
            </p>
          </div>
          <MedicationMessage>{pausedScriptNote.note.content}</MedicationMessage>
        </div>
      )}
      <MedicationsTable
        patientMedicationsDetails={patientMedicationsDetails}
        medicationName={medicationName}
      />
    </div>
  );
};

import {
  SymptomsFormValues,
  SymptomsListFormValues,
} from "~/patients/utils/consultations/intake";
import { getSelectedSymptomsKeys, symptomsQuestions } from "~/providers/utils";
import {
  formatCheckedSymptomsExperienced,
  formatSymptomDetailsAnswer,
} from "~/providers/utils/consultationRequests";
import { RawResponseLayout } from "../RawResponseLayout";

interface TextAnswerProps {
  answers: SymptomsListFormValues[];
  baseQuestion?: string;
}

export const SymptomsAnswer = ({ answers, baseQuestion }: TextAnswerProps) => {
  const answer: SymptomsFormValues = { symptoms: answers[0] };
  const selectedSymptomsKeys = getSelectedSymptomsKeys(answer);

  return (
    <>
      <RawResponseLayout question={baseQuestion}>
        <p className="normal-case">
          {formatCheckedSymptomsExperienced(answer.symptoms)}
        </p>
      </RawResponseLayout>
      {selectedSymptomsKeys.map((symptom) => (
        <RawResponseLayout question={symptomsQuestions[symptom]} key={symptom}>
          <p className="normal-case">
            {formatSymptomDetailsAnswer(symptom, answer)}
          </p>
        </RawResponseLayout>
      ))}
    </>
  );
};

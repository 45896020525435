import {
  ConsultationQuestionCodes,
  INTAKE_QUESTION_CODES,
} from "./consultations";

export const getGeneratedTitle = (questionCode?: ConsultationQuestionCodes) => {
  switch (questionCode) {
    case INTAKE_QUESTION_CODES.how_much_do_you_drink_alcohol:
      return "How much do you drink?";

    case INTAKE_QUESTION_CODES.how_much_marijuana_do_you_consume:
      return "How much marijuana do you consume?";

    default:
      return undefined;
  }
};

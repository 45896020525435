import { PropsWithChildren, ReactNode } from "react";

import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationStatus } from "~/common/types";
import { ItemCardLayout } from "~/providers/components/ItemCardLayout";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { MedicationActions } from "./MedicationActions";

interface MedicationCardLayoutProps extends PropsWithChildren {
  status: PatientMedicationStatus;
  areButtonsDisabled?: boolean;
  title: ReactNode;
  subtitle: string;
  handleModify: () => void;
  handleDiscontinue: () => void;
  pausedScriptDate?: string;
}

export const MedicationCardLayout = ({
  status,
  areButtonsDisabled = false,
  title,
  subtitle,
  handleModify,
  handleDiscontinue,
  pausedScriptDate,
  children,
}: MedicationCardLayoutProps) => {
  const { isStaff } = useIsTeamMemberStaff();

  const isDiscontinuedStatus =
    status === PATIENT_MEDICATION_STATUS.discontinued;

  return (
    <ItemCardLayout
      title={title}
      subtitle={subtitle}
      containerClassName={
        isDiscontinuedStatus ? "border-neutral-700 bg-neutral-25" : ""
      }
      additionalActions={
        <MedicationActions
          handleDiscontinue={handleDiscontinue}
          handleModify={handleModify}
          isStaff={isStaff}
          areButtonsDisabled={areButtonsDisabled}
          pausedScriptDate={pausedScriptDate}
          status={status}
        />
      }
    >
      {children}
    </ItemCardLayout>
  );
};

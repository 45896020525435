export const PostpartumIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#F5E1FF" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#E1A5FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5001 17.6257C23.9969 17.6257 26.0209 15.6016 26.0209 13.1048C26.0209 10.608 23.9969 8.58398 21.5001 8.58398C19.0033 8.58398 16.9792 10.608 16.9792 13.1048C16.9792 15.6016 19.0033 17.6257 21.5001 17.6257ZM21.5001 16.334C23.2835 16.334 24.7292 14.8882 24.7292 13.1048C24.7292 11.3214 23.2835 9.87565 21.5001 9.87565C19.7167 9.87565 18.2709 11.3214 18.2709 13.1048C18.2709 14.8882 19.7167 16.334 21.5001 16.334Z"
      fill="#BD80DA"
      stroke="#BD80DA"
      strokeWidth="0.5"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.271 28.171L19.9195 27.1548V25.0509C19.9195 24.6943 19.6304 24.4051 19.2737 24.4051C18.917 24.4051 18.6278 24.6943 18.6278 25.0509V27.6339C18.6278 27.9034 18.7951 28.1431 19.0441 28.2375L20.1766 28.6672L19.1888 29.3681C19.1113 29.4231 19.0474 29.4945 19.0011 29.576L19.9173 30.7977C20.6664 31.7963 20.4641 33.2132 19.4653 33.9623C18.4667 34.7114 17.0499 34.509 16.3008 33.5103L12.9104 28.9902C12.3869 28.2923 12.3118 27.3555 12.7174 26.5831L15.2791 21.704C15.7055 20.8918 16.4905 20.3285 17.3966 20.1847L25.2135 18.9441C26.1709 18.7921 27.1186 19.2675 27.5692 20.1258L30.2815 25.2916C30.6871 26.064 30.6119 27.0008 30.0885 27.6987L26.6981 32.2189C25.949 33.2176 24.5322 33.4199 23.5335 32.6708C22.5348 31.9218 22.3325 30.5049 23.0815 29.5062L23.8737 28.4501C23.8138 28.3955 23.7439 28.3523 23.6676 28.323L23.271 28.171ZM26.1686 27.754L25.3778 28.5964L25.378 28.5971L24.1148 30.2813C23.7938 30.7093 23.8805 31.3165 24.3085 31.6375C24.7365 31.9586 25.3437 31.8718 25.6648 31.4439L29.0551 26.9237C29.2795 26.6246 29.3117 26.2231 29.1379 25.8921L26.4256 20.7262C26.2325 20.3584 25.8264 20.1546 25.4161 20.2198L17.5991 21.4604C17.0957 21.5403 16.6596 21.8532 16.4226 22.3045L13.8609 27.1835C13.6871 27.5145 13.7194 27.9161 13.9437 28.2151L17.3341 32.7353C17.6551 33.1633 18.2624 33.25 18.6903 32.929C19.1183 32.608 19.2051 32.0008 18.884 31.5728L17.6057 29.8685L17.6066 29.863L16.4634 28.3465L17.3369 27.688C17.3364 27.67 17.3362 27.652 17.3362 27.6339V25.0509C17.3362 23.9809 18.2036 23.1134 19.2737 23.1134C20.3437 23.1134 21.2112 23.9809 21.2112 25.0509V26.1967L22.6044 26.6192C22.3141 26.1668 22.1457 25.6288 22.1457 25.0515C22.1457 23.4464 23.4469 22.1452 25.052 22.1452C26.6571 22.1452 27.9582 23.4464 27.9582 25.0515C27.9582 26.2661 27.2131 27.3068 26.155 27.7411L26.1686 27.754ZM23.4374 25.0515C23.4374 24.1598 24.1603 23.4369 25.052 23.4369C25.9437 23.4369 26.6666 24.1598 26.6666 25.0515C26.6666 25.9432 25.9437 26.666 25.052 26.666C24.1603 26.666 23.4374 25.9432 23.4374 25.0515Z"
      fill="#BD80DA"
      stroke="#BD80DA"
      strokeWidth="0.5"
      strokeLinejoin="round"
    />
  </svg>
);

import * as questions from "./questions";

export const intakeQuestionDataByCodes = {
  ...questions.textQuestions,
  ...questions.textQuestionsWithPronouns,
  ...questions.multiCheckWithBackendOptions,
  ...questions.audioQuestions,
  ...questions.relationshipQuestions,
  ...questions.multiCheckQuestions,
  ...questions.yesNoNAQuestions,
  ...questions.symptomsQuestions,
  ...questions.yesNoQuestions,
  ...questions.radioWithTextQuestions,
  ...questions.radioWithOptionalTextQuestions,
  ...questions.specialQuestions,
  ...questions.yesNoNotSureQuestions,
  ...questions.dateQuestions,
  ...questions.radioWithDateOptions,
} as const;

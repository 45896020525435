import { FieldErrors, UseFormRegister } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { UpsertMedicationFormValues } from "../UpsertMedicationModal";

interface AdditionalInformationInputsProps {
  register: (
    field: keyof UpsertMedicationFormValues["patient_medications_detail"],
  ) => ReturnType<UseFormRegister<UpsertMedicationFormValues>>;
  errors: FieldErrors<UpsertMedicationFormValues>;
}
export const AdditionalInformationInputs = ({
  register,
  errors,
}: AdditionalInformationInputsProps) => {
  const today = getTodayDate();

  return (
    <>
      <TextArea
        label="Internal note"
        {...register("internal_note")}
        error={errors.patient_medications_detail?.internal_note?.message}
      />
      <TextArea
        label="Message to patient"
        {...register("message_to_patient")}
        error={errors.patient_medications_detail?.message_to_patient?.message}
      />
      <DatePicker
        label="Start date"
        error={errors.patient_medications_detail?.start_date?.message}
        {...register("start_date")}
        min={today}
      />
    </>
  );
};

import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { Group } from "~/common/utils";
import { MultiSelectGroupListBox } from "./MultiSelectGroupListBox";

interface MultiSelectGroupProps<TFieldValues extends FieldValues> {
  control?: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  options: Group[];
  placeholder: string;
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  containerClassName?: string;
  selectAllLabel?: string;
  selectNoneLabel?: string;
  hasSelectAll?: boolean;
  hasSelectNone?: boolean;
  showCounter?: boolean;
}

export const MultiSelectGroup = <TFieldValues extends FieldValues>({
  control,
  name,
  options,
  placeholder,
  value,
  onChange,
  disabled = false,
  containerClassName,
  selectAllLabel,
  selectNoneLabel,
  hasSelectAll = true,
  hasSelectNone = true,
  showCounter = true,
}: MultiSelectGroupProps<TFieldValues>) => {
  const allValues = options.flatMap((group) =>
    group.groupOptions.map((opt) => opt.value),
  );

  return control ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: controllerOnChange, value } }) => (
        <MultiSelectGroupListBox
          defaultValues={value}
          showCounter={showCounter}
          placeholder={placeholder}
          controllerOnChange={controllerOnChange}
          options={options}
          disabled={disabled}
          containerClassName={containerClassName}
          selectAllLabel={selectAllLabel}
          selectNoneLabel={selectNoneLabel}
          handleSelectAll={
            hasSelectAll ? () => controllerOnChange(allValues) : undefined
          }
          handleSelectNone={
            hasSelectNone ? () => controllerOnChange([]) : undefined
          }
        />
      )}
    />
  ) : (
    <MultiSelectGroupListBox
      options={options}
      showCounter={showCounter}
      placeholder={placeholder}
      defaultValues={value}
      onChange={onChange}
      handleSelectAll={hasSelectAll ? () => onChange(allValues) : undefined}
      handleSelectNone={hasSelectNone ? () => onChange([]) : undefined}
      disabled={disabled}
      containerClassName={containerClassName}
      selectNoneLabel={selectNoneLabel}
      selectAllLabel={selectAllLabel}
    />
  );
};

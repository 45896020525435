import type { AxiosError } from "axios";

import { ROUTES } from "~/providers/router";
import { logoutTeamMember } from "../authService";

export const errorInterceptor = async (error: AxiosError) => {
  if (error?.response?.status === 401) {
    await logoutTeamMember();
    window.location.href = ROUTES.login;
  }

  if (error?.response?.status === 404) {
    window.location.href = ROUTES.notFound;
  }

  if (error?.response?.status === 500) {
    window.location.href = ROUTES.internalError;
  }
  return Promise.reject(error);
};

export const APPEARANCE_VALUES = {
  well_groomed: "well_groomed",
  disheveled: "disheveled",
  appropriately_dressed: "appropriately_dressed",
  inappropriately_dressed: "inappropriately_dressed",
  age_appropriate: "age_appropriate",
  younger_than_stated_age: "younger_than_stated_age",
  older_than_stated_age: "older_than_stated_age",
};

export const BEHAVIOR_VALUES = {
  cooperative: "cooperative",
  uncooperative: "uncooperative",
  agitated: "agitated",
  calm: "calm",
  hyperactive: "hyperactive",
  lethargic: "lethargic",
  alert: "alert",
};

export const SPEECH_VALUES = {
  normal_rate_and_rhythm: "normal_rate_and_rhythm",
  normal_volume: "normal_volume",
  pressured: "pressured",
  slow: "slow",
  soft: "soft",
  loud: "loud",
  monotone: "monotone",
  articulate: "articulate",
};

export const MOOD_VALUES = {
  euthymic: "euthymic",
  depressed: "depressed",
  anxious: "anxious",
  irritable: "irritable",
  elevated: "elevated",
  neutral: "neutral",
};

export const AFFECT_VALUES = {
  congruent: "congruent",
  incongruent: "incongruent",
  flat: "flat",
  blunted: "blunted",
  restricted: "restricted",
  labile: "labile",
};

export const THOUGHT_PROCESS_VALUES = {
  linear: "linear",
  tangential: "tangential",
  circumstantial: "circumstantial",
  disorganized: "disorganized",
  flight_of_ideas: "flight_of_ideas",
  goal_directed: "goal_directed",
};

export const THOUGHT_CONTENT_VALUES = {
  normal: "normal",
  delusions: "delusions",
  obsessions: "obsessions",
  hallucinations: "hallucinations",
  paranoid: "paranoid",
  phobias: "phobias",
  grandiosity: "grandiosity",
};

export const ATTENTION_AND_CONCENTRATION_VALUES = {
  focused: "focused",
  distracted: "distracted",
  hypervigilant: "hypervigilant",
  impaired: "impaired",
};

export const JUDGMENT_AND_INSIGHT_VALUES = {
  good: "good",
  fair: "fair",
  poor: "poor",
  intact_insight: "intact_insight",
  limited_insight: "limited_insight",
  no_insight: "no_insight",
};

export const MENTAL_STATUS_EXAMINATIONS_VALUES = {
  ...APPEARANCE_VALUES,
  ...BEHAVIOR_VALUES,
  ...SPEECH_VALUES,
  ...MOOD_VALUES,
  ...AFFECT_VALUES,
  ...THOUGHT_PROCESS_VALUES,
  ...THOUGHT_CONTENT_VALUES,
  ...ATTENTION_AND_CONCENTRATION_VALUES,
  ...JUDGMENT_AND_INSIGHT_VALUES,
  other: "other",
} as const;

export const MENTAL_STATUS_EXAMINATIONS_QUESTIONS = {
  appearance: "appearance",
  behavior: "behavior",
  speech: "speech",
  mood: "mood",
  affect: "affect",
  thought_process: "thought_process",
  thought_content: "thought_content",
  attention_and_concentration: "attention_and_concentration",
  judgment_and_insight: "judgment_and_insight",
} as const;

export type MentalStatusExaminationsValues =
  (typeof MENTAL_STATUS_EXAMINATIONS_VALUES)[keyof typeof MENTAL_STATUS_EXAMINATIONS_VALUES];

export type MentalStatusExaminationsQuestions =
  (typeof MENTAL_STATUS_EXAMINATIONS_QUESTIONS)[keyof typeof MENTAL_STATUS_EXAMINATIONS_QUESTIONS];

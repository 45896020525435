import { useQuery } from "@tanstack/react-query";

import { getPatientIntakeConsultationSummary } from "~/providers/api";

export const useGetPatientIntakeConsultationSummary = (patientId: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientIntakeConsultationSummary(patientId),
    queryKey: ["getPatientIntakeConsultationSummary", patientId],
  });
  return { data, isLoading };
};

interface FunctionalDomainCardRowProps {
  title: string;
  classification?: string;
  sentences?: string;
}

export const FunctionalDomainCardRow = ({
  title,
  classification,
  sentences,
}: FunctionalDomainCardRowProps) => (
  <div className="flex flex-col gap-2 rounded-lg bg-primary-50 p-2">
    <div>
      <span className="text-gray-10">{title} </span>
      {classification && (
        <span className="font-medium text-primary-600">{classification}</span>
      )}
    </div>
    {sentences && (
      <span className="font-medium italic text-gray-70">{sentences}</span>
    )}
  </div>
);

import {
  RegisterKeys,
  SymptomsFormTypes,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";

export const findAnswerOfSymptom = (
  answer: SymptomsFormValues,
  symptom: RegisterKeys,
): SymptomsFormTypes =>
  Object.fromEntries(
    Object.entries(answer.symptoms)
      .filter(([_, value]) => value?.toggled)
      .map(([key, value]) => [`symptoms.${key}` as RegisterKeys, value]),
  )[symptom];

import React from "react";

import { IconProps } from "~/common/utils";

export const SearchPatientIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="23"
    height="15"
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6599 14.2213C21.0448 14.6174 21.6779 14.6265 22.074 14.2416C22.4701 13.8567 22.4792 13.2236 22.0943 12.8275L20.6599 14.2213ZM1.94337 0.524414C1.39109 0.524414 0.943375 0.972129 0.943375 1.52441C0.943375 2.0767 1.39109 2.52441 1.94337 2.52441L1.94337 0.524414ZM7.7735 2.52441C8.32579 2.52441 8.7735 2.0767 8.7735 1.52441C8.7735 0.97213 8.32579 0.524415 7.7735 0.524415L7.7735 2.52441ZM1.94337 5.52441C1.39109 5.52441 0.943375 5.97213 0.943375 6.52441C0.943375 7.0767 1.39109 7.52441 1.94337 7.52441L1.94337 5.52441ZM5.83013 7.52441C6.38241 7.52441 6.83013 7.0767 6.83013 6.52441C6.83013 5.97213 6.38241 5.52441 5.83013 5.52441L5.83013 7.52441ZM1.94337 10.5244C1.39109 10.5244 0.943375 10.9721 0.943375 11.5244C0.943375 12.0767 1.39109 12.5244 1.94337 12.5244L1.94337 10.5244ZM7.7735 12.5244C8.32579 12.5244 8.7735 12.0767 8.7735 11.5244C8.7735 10.9721 8.32579 10.5244 7.7735 10.5244L7.7735 12.5244ZM18.3194 6.46559C18.3194 8.66951 16.5907 10.4068 14.5182 10.4068V12.4068C17.749 12.4068 20.3194 9.71954 20.3194 6.46559H18.3194ZM14.5182 10.4068C12.4456 10.4068 10.7169 8.66951 10.7169 6.46559H8.71688C8.71688 9.71954 11.2873 12.4068 14.5182 12.4068V10.4068ZM10.7169 6.46559C10.7169 4.26167 12.4456 2.52441 14.5182 2.52441V0.524414C11.2873 0.524414 8.71688 3.21164 8.71688 6.46559H10.7169ZM14.5182 2.52441C16.5907 2.52441 18.3194 4.26167 18.3194 6.46559H20.3194C20.3194 3.21164 17.749 0.524414 14.5182 0.524414V2.52441ZM17.2305 10.6919L20.6599 14.2213L22.0943 12.8275L18.6648 9.29812L17.2305 10.6919ZM1.94337 2.52441L7.7735 2.52441L7.7735 0.524415L1.94337 0.524414L1.94337 2.52441ZM1.94337 7.52441L5.83013 7.52441L5.83013 5.52441L1.94337 5.52441L1.94337 7.52441ZM1.94337 12.5244L7.7735 12.5244L7.7735 10.5244L1.94337 10.5244L1.94337 12.5244Z"
      fill="currentColor"
    />
  </svg>
);

import {
  SCREENING_CODES,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";

export const PATIENT_OVERVIEW_KEYS = {
  self_reported: "self-reported",
  clinical_dimensions: "clinical dimensions",
} as const;

export const patientOverviewData = {
  [PATIENT_OVERVIEW_KEYS.self_reported]: {
    id: 1,
    description: (
      <p className="text-sm">
        These self-reported scores assess the levels of depression, anxiety, and
        ADHD symptoms.
      </p>
    ),
    bottomDescription: (
      <p className="text-sm">
        These scores provide an overview of the patient’s self-reported mental
        health, helping guide further investigation and care.
      </p>
    ),
    options: [
      {
        id: 1,
        content: (
          <p>
            <strong>{screeningSectionTitle[SCREENING_CODES.phq9]}:</strong> This
            score evaluates depression severity. Ranging from 0 to 27, it
            categorizes depression as Minimal (0-4), Mild (5-9), Moderate
            (10-14), Moderately Severe (15-19), and Severe (20-27).
          </p>
        ),
      },
      {
        id: 2,
        content: (
          <p>
            <strong>{screeningSectionTitle[SCREENING_CODES.gad7]}:</strong> This
            score assesses anxiety levels, with a range from 0 to 21. Anxiety
            severity is classified as Minimal (0-4), Mild (5-9), Moderate
            (10-14), and Severe (15-21).
          </p>
        ),
      },
      {
        id: 3,
        content: (
          <p>
            <strong>
              {screeningSectionTitle[SCREENING_CODES.asrs]} Part A:
            </strong>{" "}
            The Adult ADHD Self-Report Scale screens for ADHD symptoms, with
            scores of 14 or higher suggesting clinically significant symptoms
            that warrant further evaluation.
          </p>
        ),
      },
    ],
  },
  [PATIENT_OVERVIEW_KEYS.clinical_dimensions]: {
    id: 1,
    description: (
      <p className="text-sm">
        The significant clinical dimensions reflect key areas of the patient’s
        mental health, derived from analysis of data collected through text and
        audio. <br />
        <br />
        These data are classified into five primary dimensions (in parenthesis
        you will see the specific labels that fall under each category, shown in
        the relevant section):
      </p>
    ),
    bottomDescription: (
      <p className="text-sm">
        These dimensions enable providers to identify areas most relevant for
        further exploration, with those highlighted in blue indicating potential
        areas of concern, while those in grey show no signs of concern.
      </p>
    ),
    options: [
      {
        id: 1,
        content: (
          <p>
            <strong>(a) Substance use</strong> (Problematic drug use, Drinks too
            much, Friends concerned: Substance use)
          </p>
        ),
      },
      {
        id: 2,
        content: (
          <p>
            <strong>(b) Impulsivity</strong> (Self harm, Dangerous or risky
            behaviors, Worrisome attitude towards food, Body image)
          </p>
        ),
      },
      {
        id: 3,
        content: (
          <p>
            <strong>(c) Personality pathology</strong> (Feels empty, Feels
            abandoned, Anger management)
          </p>
        ),
      },
      {
        id: 4,
        content: (
          <p>
            <strong>(d) Eating disorder</strong> (Worrisome attitude towards
            food, Body image)
          </p>
        ),
      },
    ],
  },
};

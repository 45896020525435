import { useState } from "react";

import {
  IntakeCode,
  intakeSectionTitle,
} from "~/common/utils/consultations/intake";
import {
  ScreeningCode,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";
import { PatientData } from "~/providers/utils";
import { isIntakeResponses } from "~/providers/utils/consultationRequests/rawResponses";
import {
  PatientRawResponsesIntake,
  PatientRawResponsesScreening,
} from "~/providers/utils/types/rawResponsesTypes";
import { ExpandCollapseButtons } from "./ExpandCollapseButtons";
import { RawResponseSectionCard } from "./RawResponseSectionCard";
import { RawResponsesRender } from "./RawResponsesRender";

interface RawResponsesSectionProps {
  rawResponses: PatientRawResponsesIntake | PatientRawResponsesScreening;
  patient?: PatientData;
}

const RawResponsesSection = ({
  rawResponses: rawResponses,
  patient,
}: RawResponsesSectionProps) => {
  const isIntakeResponse = isIntakeResponses(rawResponses);
  const sections = isIntakeResponse
    ? rawResponses.intakes
    : rawResponses.screenings;

  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >(sections.reduce((acc, { code }) => ({ ...acc, [code]: true }), {}));

  const toggleExpandAll = (expand: boolean) => {
    const newExpandedState = sections.reduce(
      (acc, { code }) => ({ ...acc, [code]: expand }),
      {},
    );
    setExpandedSections(newExpandedState || {});
  };

  const toggleSection = (code: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h2 className="font-outfit text-2xl font-medium text-gray-800">
          {isIntakeResponse ? "Intake" : "Screening"}
        </h2>
        <ExpandCollapseButtons
          isExpandedAll={Object.values(expandedSections).every(Boolean)}
          setIsExpandedAll={toggleExpandAll}
        />
      </div>
      {sections.map(({ code, order, answers }) => (
        <RawResponseSectionCard
          key={code}
          isExpanded={expandedSections[code] ?? false}
          toggleSection={() => toggleSection(code)}
          order={order}
          title={
            isIntakeResponse
              ? intakeSectionTitle[code as IntakeCode]
              : screeningSectionTitle[code as ScreeningCode]
          }
        >
          {answers?.map((answer, index) => (
            <RawResponsesRender
              answer={answer}
              isIntake={isIntakeResponse}
              pronouns={patient?.pronouns}
              key={`${code} answer ${index}`}
            />
          ))}
        </RawResponseSectionCard>
      ))}
    </div>
  );
};

export default RawResponsesSection;

import { z } from "zod";

import { SHORT_TEXT_MAX_LENGTH } from "~/providers/constants";
import {
  MentalStatusExaminationsQuestions,
  MentalStatusExaminationsValues,
} from "~/providers/utils/mentalStatusExaminations";

export interface MultiCheckMentalStatusOptions {
  value: MentalStatusExaminationsValues;
  label: string;
  asksExtraInfo?: boolean;
  isExclusiveOption?: boolean;
  asksCommonExtraData?: boolean;
  title?: string;
}

export interface MentalStatusExaminationMultiCheckValue {
  value: MentalStatusExaminationsValues;
  extra_data?: string;
}

export interface CompletedMultiCheckAnswer {
  values?: MentalStatusExaminationMultiCheckValue[];
  extra_data?: string;
}

export interface MultiCheckQuestionData {
  title: string;
  key: MentalStatusExaminationsQuestions;
  options: MultiCheckMentalStatusOptions[];
  subtitle?: string;
  supertitle?: string;
  commonExtraDataLabel?: string;
}

export const createMultiCheckSchemaMentalStatusExaminations = (
  options: readonly MultiCheckMentalStatusOptions[],
  required = false,
) => {
  const values = options.map((option) => option.value) as [
    MentalStatusExaminationsValues,
    ...MentalStatusExaminationsValues[],
  ];

  const baseValuesSchema = z.array(
    z.object({
      value: z.enum(values),
      extra_data: z
        .string()
        .max(
          SHORT_TEXT_MAX_LENGTH,
          `The limit of characters is ${SHORT_TEXT_MAX_LENGTH}`,
        )
        .optional()
        .nullable(),
    }),
  );

  const valuesSchema = required
    ? baseValuesSchema.refine((data) => data.length > 0, {
        message: "Please select at least one option.",
      })
    : baseValuesSchema;

  const mainSchema = z.object({
    values: valuesSchema,
    extra_data: z.string().optional(),
  });

  return required ? mainSchema : mainSchema.optional();
};

import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import AuthenticationLayout from "~/common/components/layouts/AuthenticationLayout";
import FormLayout from "~/common/components/layouts/FormLayout";
import Input from "~/common/components/ui/Input";
import { providersWelcomeText } from "../constants";
import { useLogin } from "../hooks/queries/auth/useLogin";
import { ROUTES } from "../router";
import { authenticateProviderSchema } from "../utils/ProviderSchemas";

export type LogInFormValues = z.infer<typeof authenticateProviderSchema>;
export const Login = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<LogInFormValues>({
    resolver: zodResolver(authenticateProviderSchema),
  });

  const { loginMutation, isPending } = useLogin(setError);

  const onSubmit: SubmitHandler<LogInFormValues> = (data) =>
    loginMutation(data);

  return (
    <AuthenticationLayout welcomeText={providersWelcomeText}>
      <FormLayout
        handleSubmit={handleSubmit(onSubmit)}
        submitLabel="Log in"
        title="Log in"
        pendingRequest={isPending}
      >
        <Input
          id="email"
          label="Email address"
          {...register("email")}
          error={errors.email?.message}
        />
        <div className="flex w-full flex-col items-end gap-2">
          <Input
            id="password"
            type="password"
            label="Password"
            {...register("password")}
            error={errors.password?.message}
          />
          <Link
            to={ROUTES.forgotPassword}
            className="border-b border-gray-500 text-sm"
          >
            Forgot password
          </Link>
        </div>
      </FormLayout>
    </AuthenticationLayout>
  );
};

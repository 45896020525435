export const EnjoymentIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#FDEED1" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#FBCD75" />
    <g clipPath="url(#clip0_6287_11838)">
      <path
        d="M17.9168 17.8125H18.2812M17.9168 18.1875H18.2812M18.585 18C18.585 18.2761 18.3674 18.5 18.0991 18.5C17.8308 18.5 17.6133 18.2761 17.6133 18C17.6133 17.7239 17.8308 17.5 18.0991 17.5C18.3674 17.5 18.585 17.7239 18.585 18Z"
        stroke="#D7A850"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.7186 17.8125H25.083M24.7186 18.1875H25.083M25.3867 18C25.3867 18.2761 25.1692 18.5 24.9009 18.5C24.6326 18.5 24.415 18.2761 24.415 18C24.415 17.7239 24.6326 17.5 24.9009 17.5C25.1692 17.5 25.3867 17.7239 25.3867 18Z"
        stroke="#D7A850"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.6416 24.5C17.0465 25.5 18.585 27.5 21.5 27.5C24.4151 27.5 25.9536 25.5 26.3585 24.5"
        stroke="#D7A850"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.5001 32.5C27.4032 32.5 32.1887 27.5751 32.1887 21.5C32.1887 15.4249 27.4032 10.5 21.5001 10.5C15.597 10.5 10.8115 15.4249 10.8115 21.5C10.8115 27.5751 15.597 32.5 21.5001 32.5Z"
        stroke="#D7A850"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6287_11838">
        <rect
          width="23.3205"
          height="24"
          fill="white"
          transform="translate(9.83984 9.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

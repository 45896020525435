import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createActivityFeedNote } from "~/providers/api";

export const useCreateActivityFeedNote = (
  patientId: string,
  handleCloseModal: () => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: createActivityFeedNoteMutation, isPending } = useMutation({
    mutationFn: (note: string) => createActivityFeedNote({ patientId, note }),
    onError: () =>
      toast.error(
        "There was an issue creating this note. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientFeeds"],
      });
      handleCloseModal();
      toast.success("A new note was successfully added to the activity feed.");
    },
  });

  return { createActivityFeedNoteMutation, isPending };
};

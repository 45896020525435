import {
  RegisterKeys,
  SymptomsFormTypes,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";

export const sleepIssuesSymptomsBaseKey: RegisterKeys = "symptoms.sleep_issues";

export const getSleepIssuesAnswersWithKeys = (
  answer: SymptomsFormValues,
): Partial<Record<RegisterKeys, string>> =>
  Object.entries(answer.symptoms.sleep_issues)
    .filter(([_, value]) => {
      const v = value as SymptomsFormTypes;
      return v?.toggled;
    })
    .flatMap(([key, value]) => {
      if (key === "decreased_need_for_sleep") {
        return Object.entries(value)
          .filter(([k, v]) => k !== "toggled" && typeof v === "string")
          .map(([k, v]: [string, string]) => ({
            [`${sleepIssuesSymptomsBaseKey}.${key}.${k}`]: v,
          }));
      }
      if (typeof value === "object" && "extra_data" in value) {
        return {
          [`${sleepIssuesSymptomsBaseKey}.${key}`]: value.extra_data,
        };
      }
      return [];
    })
    .filter((a): a is Record<RegisterKeys, string> => a !== undefined)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

import {
  SummaryFeedback,
  SummaryType,
} from "~/providers/utils/types/patientOverviewTypes";

export const isFeedbackLiked = (
  type: SummaryType,
  feedbacks?: SummaryFeedback[],
) =>
  feedbacks?.find((feedback) => feedback.consultation_type === type)?.is_like;

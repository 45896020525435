import { matchPath, NavigateFunction } from "react-router-dom";

import { ROUTES } from "../router";

interface ProviderSettingsMenuOptions {
  label: string;
  onClick: () => void;
  current?: boolean;
  enabled: boolean;
}

export const getSettingsLayoutMenuOptions = (
  navigate: NavigateFunction,
  isStaff: boolean,
) => {
  const actualPath = window.location.pathname;

  const menuOptions: ProviderSettingsMenuOptions[] = [
    {
      label: "Account",
      onClick: () => {
        navigate(ROUTES.account);
      },
      current: !!matchPath(ROUTES.account, actualPath),
      enabled: true,
    },
    {
      label: "Payments & Care Plans",
      onClick: () => {
        navigate(ROUTES.paymentAndCarePlans);
      },
      current: !!matchPath(ROUTES.paymentAndCarePlans, actualPath),
      enabled: !isStaff,
    },
  ];

  return { menuOptions };
};

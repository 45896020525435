import { useGetPatientIntakeConsultationSummary } from "./useGetPatientIntakeConsultationSummary";
import { useGetPatientScreeningConsultationSummary } from "./useGetPatientScreeningConsultationSummary";

export const useGetPatientConsultationSummary = (patientId: string) => {
  const {
    data: screeningSummaryData,
    isLoading: isLoadingScreeningSummaryData,
  } = useGetPatientScreeningConsultationSummary(patientId);

  const { data: intakeSummaryData, isLoading: isLoadingIntakeSummaryData } =
    useGetPatientIntakeConsultationSummary(patientId);

  const isLoading = isLoadingScreeningSummaryData && isLoadingIntakeSummaryData;

  return { isLoading, screeningSummaryData, intakeSummaryData };
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { TeamMember } from "~/common/utils";
import { UpdateTeamMemberData, updateTeamMemberData } from "~/providers/api";

export const useUpdateTeamMemberDetails = (
  onSuccess: (data: TeamMember) => Promise<void>,
) => {
  const { mutate: updateTeamMemberDataMutation, isPending } = useMutation({
    mutationFn: (data: UpdateTeamMemberData) => updateTeamMemberData(data),
    onError: () =>
      toast.error(
        "There was an issue updating your information. Please try again later.",
      ),
    onSuccess,
  });

  return { updateTeamMemberDataMutation, isPending };
};

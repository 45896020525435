import {
  RegisterKeys,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";
import {
  getSleepIssuesAnswersWithKeys,
  sleepIssuesSymptomsBaseKey,
} from "./formatSymptomsDetailsAnswers";

export const getSelectedSymptomsKeys = (answer: SymptomsFormValues) => {
  let sleepIssuesAnswers: Partial<Record<RegisterKeys, string>> = {};

  let selectedSymptoms = Object.entries(answer.symptoms)
    .filter(([_, value]) => value?.toggled)
    .map(([key]) => `symptoms.${key}` as RegisterKeys);

  if (
    selectedSymptoms.includes(sleepIssuesSymptomsBaseKey) &&
    answer.symptoms.sleep_issues
  ) {
    sleepIssuesAnswers = getSleepIssuesAnswersWithKeys(answer);
    const sleepIssueIndex = selectedSymptoms.indexOf(
      sleepIssuesSymptomsBaseKey,
    );

    const sleepIssuesKeys = [sleepIssuesSymptomsBaseKey].concat(
      Object.keys(sleepIssuesAnswers) as RegisterKeys[],
    );

    selectedSymptoms = [
      ...selectedSymptoms.slice(0, sleepIssueIndex),
      ...sleepIssuesKeys,
      ...selectedSymptoms.slice(sleepIssueIndex + 1),
    ];
  }

  return selectedSymptoms;
};

import { PropsWithChildren } from "react";

import { ChevronIcon, Table, TableHeader } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";

interface RawResponseSectionCardProps extends PropsWithChildren {
  isExpanded: boolean;
  order: number;
  title: string;
  toggleSection: () => void;
}

export const RawResponseSectionCard = ({
  isExpanded,
  order,
  title,
  children,
  toggleSection,
}: RawResponseSectionCardProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <button
        className="flex h-auto w-full items-center justify-between rounded-lg bg-primary-100 px-5 py-3"
        onClick={(e) => {
          e.stopPropagation();
          toggleSection();
        }}
      >
        <p className="text-lg">
          Section {order} - <span className="font-medium">{title}</span>
        </p>
        <ChevronIcon
          className="size-2.5 text-gray-10"
          direction={isExpanded ? DIRECTIONS.top : DIRECTIONS.down}
        />
      </button>
      {isExpanded && (
        <Table className="size-full overflow-hidden rounded-lg border border-gray-200">
          <tbody>
            <tr className="w-full rounded-lg">
              <TableHeader className="w-1/2">Question</TableHeader>
              <TableHeader className="w-1/2">Response</TableHeader>
            </tr>
            {children}
          </tbody>
        </Table>
      )}
    </div>
  );
};

import { z } from "zod";

import { appetiteChangesSchema } from "./appetiteChangesSymptom";
import {
  baseOptionSchema,
  toggleRequiredExtraDataSchema,
  toggleTextExtraDataSchema,
} from "./baseSchemas";
import { depressionSchema } from "./depressionSymptom";
import { foodWeightPreoccupationsSchema } from "./foodWeightPreoccupationsSymptom";
import { sleepIssuesSchema } from "./sleepIssuesSymptom";
import { suicidalHarmfulThoughtsSchema } from "./suicidalHarmfulSymptom";

export const symptomsListSchema = z.object({
  depression: depressionSchema,
  sleep_issues: sleepIssuesSchema,
  attention_focus_issues: toggleTextExtraDataSchema,
  anxiety: toggleTextExtraDataSchema,
  panic_attacks: toggleRequiredExtraDataSchema,
  increased_spending_or_shopping: toggleTextExtraDataSchema,
  appetite_changes: appetiteChangesSchema,
  nightmares: toggleTextExtraDataSchema,
  flashbacks: toggleTextExtraDataSchema,
  memory_problems: toggleRequiredExtraDataSchema,
  food_weight_preoccupations: foodWeightPreoccupationsSchema,
  obsessions: toggleRequiredExtraDataSchema,
  compulsions: toggleRequiredExtraDataSchema,
  diminished_interests: toggleTextExtraDataSchema,
  irritability: toggleTextExtraDataSchema,
  grand_ideas: toggleTextExtraDataSchema,
  impulsivity: toggleTextExtraDataSchema,
  suicidal_harmful_thoughts: suicidalHarmfulThoughtsSchema,
  none: baseOptionSchema,
  other: toggleTextExtraDataSchema,
});

export const symptomsSchema = z
  .object({
    symptoms: symptomsListSchema,
  })
  .refine(
    (data) => {
      return Object.values(data.symptoms).some((symptom) => symptom?.toggled);
    },
    {
      message:
        "Please select at least one pattern of depression are you experiencing.",
      path: ["symptoms"],
    },
  );

export type SymptomsListFormValues = z.infer<typeof symptomsListSchema>;
export type SymptomsFormValues = z.infer<typeof symptomsSchema>;

type NestedKeys<T, Prefix extends string = ""> = {
  [K in keyof T & string]: T[K] extends object
    ? `${Prefix}${K}` | NestedKeys<T[K], `${Prefix}${K}.`>
    : `${Prefix}${K}`;
}[keyof T & string];

export type RegisterKeys = NestedKeys<
  SymptomsFormValues["symptoms"],
  "symptoms."
>;

export type SymptomsFormTypes =
  | z.infer<typeof depressionSchema>
  | z.infer<typeof sleepIssuesSchema>
  | z.infer<typeof appetiteChangesSchema>
  | z.infer<typeof suicidalHarmfulThoughtsSchema>
  | z.infer<typeof toggleTextExtraDataSchema>
  | z.infer<typeof baseOptionSchema>
  | z.infer<typeof foodWeightPreoccupationsSchema>;

import { twMerge } from "tailwind-merge";

import { Table } from "~/common/components";
import { Patient } from "~/common/utils";
import { PatientSearchTableBody } from "./PatientSearchTableBody";
import { PatientSearchTableHead } from "./PatientSearchTableHead";

interface PatientSearchTableProps {
  isLoading?: boolean;
  patients: Patient[];
}

export const PatientSearchTable = ({
  patients,
  isLoading,
}: PatientSearchTableProps) => {
  const noPatients = !patients.length;

  return (
    <div
      className={twMerge(
        "w-full rounded-2xl border border-gray-200",
        noPatients && "h-table overflow-clip",
      )}
    >
      <Table>
        <PatientSearchTableHead />
        <PatientSearchTableBody patients={patients} isLoading={isLoading} />
      </Table>
    </div>
  );
};

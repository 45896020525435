export const FamilyIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#DAE0FB" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#92A2F3" />
    <path
      d="M21.5001 26.5V30.5M19.5567 30.5H23.4434M21.1703 25.4352C20.2899 26.1035 19.1923 26.5 18.002 26.5C15.1041 26.5 12.7549 24.1495 12.7549 21.25C12.7549 19.2505 13.8721 17.512 15.516 16.6255C16.0911 16.3153 16.5709 15.8275 16.888 15.2444C17.777 13.6096 19.509 12.5 21.5001 12.5C23.4911 12.5 25.2231 13.6096 26.1122 15.2444C26.4293 15.8275 26.909 16.3153 27.4841 16.6255C29.1281 17.512 30.2453 19.2505 30.2453 21.25C30.2453 24.1495 27.896 26.5 24.9981 26.5C23.8079 26.5 22.7102 26.1035 21.8298 25.4352C21.6348 25.2872 21.3654 25.2872 21.1703 25.4352Z"
      stroke="#6D7ECF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

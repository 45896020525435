import { twMerge } from "tailwind-merge";

import { EmptyTableState, Table, TableHead } from "~/common/components/table";
import { TableHeader } from "~/common/components/table/TableHeader";
import { Script } from "~/common/types/scriptsTypes";
import { Option } from "~/common/utils";
import { formatPrescriptionRequestData } from "~/providers/utils";
import { PrescriptionRequestRow } from "./PrescriptionRequestRow";

interface PrescriptionRequestTableProps {
  prescriptionRequests?: Script[];
  ownersOptions: Option[];
  isLoading: boolean;
  isFetched: boolean;
  noPrescriptionRequestsAssigned: boolean;
}

export const PrescriptionRequestTable = ({
  prescriptionRequests,
  ownersOptions,
  isLoading,
  isFetched,
  noPrescriptionRequestsAssigned,
}: PrescriptionRequestTableProps) => (
  <div
    className={twMerge(
      "w-full overflow-hidden overflow-x-auto rounded-xl border border-gray-200",
      !prescriptionRequests?.length && "h-table",
    )}
  >
    <Table className="min-w-max">
      <TableHead>
        <tr className="w-full">
          <TableHeader className="rounded-tl-xl">Name</TableHeader>
          <TableHeader className="w-1/5">Medication</TableHeader>
          <TableHeader className="w-32">Created on</TableHeader>
          <TableHeader className="w-1/6">Owner</TableHeader>
          <TableHeader className="w-24">Pre-auth</TableHeader>
          <TableHeader className="w-40">Provider approval</TableHeader>
          <TableHeader className="w-44 rounded-tr-xl">Status</TableHeader>
        </tr>
      </TableHead>
      <tbody className="bg-white text-sm text-gray-70">
        {!isLoading && isFetched && !prescriptionRequests?.length && (
          <EmptyTableState
            colSpan={7}
            message={
              noPrescriptionRequestsAssigned
                ? "There aren't any prescription requests yet."
                : "No prescription requests match your search criteria."
            }
          />
        )}
        {prescriptionRequests?.map((prescriptionRequest) => (
          <PrescriptionRequestRow
            key={`${prescriptionRequest.id}-${prescriptionRequest.owner?.id}`}
            {...formatPrescriptionRequestData(prescriptionRequest)}
            ownersOptions={ownersOptions}
            isLoading={isLoading}
          />
        ))}
      </tbody>
    </Table>
  </div>
);

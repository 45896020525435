import { matchPath, useNavigate } from "react-router-dom";

import { ChartIcon, ListIcon } from "~/common/components";
import HomeIcon from "~/common/components/icons/HomeIcon";
import LogOutIcon from "~/common/components/icons/LogOutIcon";
import { SearchPatientIcon } from "~/common/components/icons/SearchPatientIcon";
import SettingsIcon from "~/common/components/icons/SettingsIcon";
import { PATIENT_SCRIPT_STATUS } from "~/common/constants";
import { SCRIPT_FILTERS } from "../constants";
import { BASE_PATIENT_PROFILE, BASE_SETTINGS_PATH, ROUTES } from "../router";
import { useUiStore } from "../stores";
import { useLogoutTeamMember } from "./queries";

export const useSideBarItems = () => {
  const { logoutMutation } = useLogoutTeamMember();
  const { setSideBarOpen } = useUiStore();
  const navigate = useNavigate();
  const actualPath = window.location.pathname;

  const menuItems = [
    {
      current:
        !!matchPath(ROUTES.home, actualPath) ||
        !!matchPath(`${BASE_PATIENT_PROFILE}/*`, actualPath),
      Icon: HomeIcon,
      name: "Home",
      onClick: () => {
        setSideBarOpen(false);
        navigate(ROUTES.home);
      },
    },
    {
      current: !!matchPath(`${ROUTES.consultationRequests}/*`, actualPath),
      Icon: ChartIcon,
      name: "Consultation requests",
      onClick: () => {
        setSideBarOpen(false);
        navigate(ROUTES.consultationRequests);
      },
    },
    {
      current: !!matchPath(actualPath, ROUTES.prescriptionRequest),
      Icon: ListIcon,
      name: "Prescription requests",
      onClick: () => {
        setSideBarOpen(false);
        navigate(
          `${ROUTES.prescriptionRequest}?${SCRIPT_FILTERS.status}=${PATIENT_SCRIPT_STATUS.pending}`,
        );
      },
    },
  ];

  const bottomMenuItems = [
    {
      current: !!matchPath(ROUTES.patientSearch, actualPath),
      Icon: SearchPatientIcon,
      name: "Patient search",
      onClick: () => {
        navigate(ROUTES.patientSearch);
      },
    },
    {
      current: !!matchPath(`${BASE_SETTINGS_PATH}/*`, actualPath),
      Icon: SettingsIcon,
      name: "Settings",
      onClick: () => {
        setSideBarOpen(false);
        navigate(ROUTES.account);
      },
    },
    {
      Icon: LogOutIcon,
      name: "Sign Out",
      onClick: logoutMutation,
    },
  ];

  return { menuItems, bottomMenuItems };
};

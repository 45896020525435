import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getCurrentTeamMember } from "~/providers/api";

interface UseGetCurrentTeamMemberProps {
  enabled?: boolean;
  retry?: number;
}

export const useGetCurrentTeamMember = (
  props?: UseGetCurrentTeamMemberProps,
) => {
  const { enabled, retry } = { enabled: true, retry: 4, ...(props ?? {}) };
  const queryClient = useQueryClient();

  const teamMemberIsLoggedIn = !!queryClient.getQueryData([
    "getTeamMemberIsAuth",
  ]);
  const isEnabled = enabled && teamMemberIsLoggedIn;

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: getCurrentTeamMember,
    queryKey: ["getCurrentTeamMember"],
    staleTime: 1000 * 60 * 30, // 30 minutes,
    enabled: isEnabled,
    retry,
  });

  return { data, isLoading, isFetching, refetch };
};

import { z } from "zod";

import { TEXT_MAX_LENGTH } from "../constants";

export const addActivityFeedNoteSchema = z.object({
  note: z
    .string()
    .min(1, "Note is a mandatory field.")
    .max(TEXT_MAX_LENGTH, `Maximum ${TEXT_MAX_LENGTH} characters allowed`),
});

import { SectionHeader, Typography } from "~/common/components";
import { capitalizeAndJoinWords } from "~/common/utils";
import { replaceUnderscores } from "~/common/utils/replaceUnderscores";
import { CompletedMultiCheckAnswer } from "~/providers/utils/mentalStatusExaminations";
import { formatMentalStatusExaminationsAnswers } from "~/providers/utils/mentalStatusExaminations/formatMentalStatusExaminationsAnswers";

interface CompletedMentalStatusExaminationProps {
  results: [string, CompletedMultiCheckAnswer | null][];
}

export const CompletedMentalStatusExamination = ({
  results,
}: CompletedMentalStatusExaminationProps) => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-md border border-neutral-80 p-5">
      <SectionHeader className="font-sans text-xl">
        Mental Status Examination
      </SectionHeader>
      <div className="grid gap-4 md:grid-cols-3">
        {results.map(([title, object]) => {
          const answers = formatMentalStatusExaminationsAnswers(object);
          return (
            <div className="flex flex-col items-start" key={title}>
              <Typography className="text-sm text-gray-10 first-letter:uppercase">
                {replaceUnderscores(title)}
              </Typography>
              <Typography className="text-sm text-gray-70 first-letter:uppercase">
                {capitalizeAndJoinWords(answers)}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

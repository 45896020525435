import { useQuery } from "@tanstack/react-query";

import { getTeamMemberIsAuth } from "~/providers/api";

export const useTeamMemberIsAuth = () => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => getTeamMemberIsAuth(),
    queryKey: ["getTeamMemberIsAuth"],
    staleTime: Infinity,
    retry: 2,
  });
  return { data, isLoading, isFetching, refetch };
};

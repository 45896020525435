import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { ChevronIcon } from "~/common/components/icons";
import { DIRECTIONS } from "~/common/constants";
import { Group } from "~/common/utils";
import CheckBox from "~/patients/components/ui/CheckBox";

interface MultiSelectGroupListBoxProps {
  options: Group[];
  disabled?: boolean;
  selectAllLabel?: string;
  selectNoneLabel?: string;
  placeholder?: string;
  defaultValues: string[];
  containerClassName?: string;
  showCounter?: boolean;
  onChange?: (value: string[]) => void;
  controllerOnChange?: (value: string[]) => void;
  handleSelectAll?: () => void;
  handleSelectNone?: () => void;
}

export const MultiSelectGroupListBox = ({
  options,
  containerClassName,
  controllerOnChange,
  defaultValues,
  disabled,
  handleSelectAll,
  handleSelectNone,
  showCounter = true,
  onChange,
  placeholder,
  selectAllLabel = "Select all",
  selectNoneLabel = "Select none",
}: MultiSelectGroupListBoxProps) => (
  <Listbox
    disabled={disabled}
    value={defaultValues}
    multiple
    onChange={(val) => {
      onChange?.(val);
      controllerOnChange?.(val);
    }}
  >
    {({ open }) => (
      <div className={twMerge("relative", containerClassName)}>
        <Listbox.Button
          aria-disabled={disabled}
          className={twMerge(
            "relative flex w-full min-w-44 items-center justify-between gap-3 rounded-lg border px-3 py-2.5 text-left sm:text-sm",
            "border-gray-70 bg-neutral-25 text-gray-70",
            "disabled:border-gray-20 disabled:bg-neutral-5 disabled:text-gray-60",
            "hover:bg-neutral-10",
          )}
        >
          <div
            className={twMerge(
              "flex items-center gap-1.5",
              disabled && "text-gray-600",
            )}
          >
            {placeholder}
            {showCounter && Boolean(defaultValues.length) && (
              <div className="flex size-5 items-center justify-center rounded-full bg-primary-500 text-xs text-white">
                {defaultValues.length}
              </div>
            )}
          </div>
          <ChevronIcon
            direction={open ? DIRECTIONS.top : DIRECTIONS.down}
            className={twMerge(
              "h-3 transition-all duration-300",
              disabled && "text-gray-600",
            )}
          />
        </Listbox.Button>
        {!disabled && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="scrollbar-hide border-gray-25 absolute z-50 mt-1 flex max-h-60 w-full flex-col gap-3 overflow-auto rounded-md border border-gray-200 bg-white p-3 text-left text-base shadow-lg sm:text-sm">
              <div
                className={twMerge("flex w-full items-center justify-between")}
              >
                {handleSelectAll && (
                  <button
                    type="button"
                    onClick={handleSelectAll}
                    className="w-fit select-none font-medium text-gray-30"
                  >
                    {selectAllLabel}
                  </button>
                )}
                {handleSelectNone && (
                  <button
                    type="button"
                    onClick={handleSelectNone}
                    className="w-fit select-none font-medium text-gray-30"
                  >
                    {selectNoneLabel}
                  </button>
                )}
              </div>
              <div className="flex flex-col gap-1">
                {options.map(({ group, groupOptions }) => (
                  <>
                    <span
                      key={group}
                      className="text-xs font-medium text-gray-20"
                    >
                      {group}
                    </span>
                    <div className="flex w-full flex-col gap-3">
                      {groupOptions.map(({ value, label }) => (
                        <Listbox.Option
                          key={value}
                          className="relative select-none first-letter:uppercase"
                          value={value}
                        >
                          <button className="flex flex-row items-center gap-1">
                            <CheckBox
                              readOnly
                              id={value}
                              checked={defaultValues?.includes(value)}
                              parentClassName="w-fit"
                            />
                            <label className="cursor-pointer first-letter:uppercase">
                              {label}
                            </label>
                          </button>
                        </Listbox.Option>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </Listbox.Options>
          </Transition>
        )}
      </div>
    )}
  </Listbox>
);

import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface ToolTipProps extends PropsWithChildren {
  className?: string;
  tooltip: string;
}

export const ToolTip = ({ children, className, tooltip }: ToolTipProps) => (
  <div className="group relative inline-block">
    <div className="shadow-xl">
      <div
        className={twMerge(
          "invisible absolute -left-4 bottom-full z-50 mb-5 min-w-[17rem] rounded-lg bg-primary-600 p-3 text-sm font-light text-white opacity-0 transition group-hover:visible group-hover:opacity-100",
          className,
        )}
      >
        {tooltip}
      </div>
      <div className="invisible absolute -top-8 right-0.5 h-5 w-5 rotate-45 rounded-sm bg-primary-600 opacity-0 transition group-hover:visible group-hover:opacity-100" />
    </div>
    <div className="cursor-pointer">{children}</div>
  </div>
);

import { isBefore } from "date-fns";
import { z } from "zod";

import { OTHER_VALUE } from "~/common/constants";
import { usaStates } from "~/common/constants/usaStates";
import { PRONOUNS } from "../constants";
import { relationshipValues } from "../constants/relationships";
import { pharmacySchema } from "./pharmacySchema";

export const termsAndConditionsSchema = z.object({
  termsAndConditions: z.boolean().refine((value) => value === true, {
    message: "You must agree to the terms and conditions",
  }),
});
export const namesSchema = z.object({
  name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
});

export const userMobileSchema = z.object({
  phone_number: z
    .string()
    .min(1, "Mobile number required")
    .regex(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, {
      message: "Please enter mobile number with format: (123) 456-7890",
    }),
});

export const patientAddressSchema = z.object({
  street: z.string().min(1, "Street is required"),
  apartment: z.string(),
  city: z.string().min(1, "City is required"),
  state: z.enum(usaStates, {
    errorMap: () => ({ message: "State is required" }),
  }),
  zipcode: z.string().length(5, "ZIP Code must have 5 digits."),
});

export const emergencyContactSchema = z
  .object({
    name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    relationship: z.enum(relationshipValues, {
      errorMap: () => ({ message: "Please select an option" }),
    }),
    customRelationship: z.string().optional(),
    email: z.string().email("Please provide a valid email address."),
    phone_number: z
      .string()
      .min(1, "Mobile number required")
      .regex(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, {
        message: "Please enter mobile number with format: (123) 456-7890",
      }),
  })
  .refine(
    (data) => {
      const valid = !(
        (data.relationship === OTHER_VALUE && !data.customRelationship) ||
        (data.customRelationship && /\d/.test(data.customRelationship))
      );
      return valid;
    },
    {
      path: ["customRelationship"],
      message: "Please enter a relationship",
    },
  );

export const passwordSchema = z
  .object({
    password: z.string(),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message:
      "Passwords don't match. Please make sure to enter the same password in both fields.",
  })
  .refine(
    (data) => {
      if (data.password !== data.passwordConfirmation) return true;
      const passwordRegex =
        // eslint-disable-next-line  no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ])[A-Za-z\d`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]{8,}$/;
      const valid = passwordRegex.test(data.password);
      return valid;
    },
    {
      path: ["password"],
      message: "Passwords don't meet the requirements.",
    },
  );

export const pronounsSchema = z
  .object({
    pronouns: z.string({
      required_error: "Please select an option",
    }),
    customPronouns: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.pronouns !== PRONOUNS.other) return true;
      const valid =
        data.customPronouns && /^\w+\/\w+\/\w+$/.test(data.customPronouns);
      return valid;
    },
    {
      path: ["customPronouns"],
      message: "Enter 3 pronouns separated by '/'",
    },
  );

export const PATIENTS_TYPES = {
  newPatient: "newPatient",
  activePatient: "activePatient",
} as const;

export const namesAndEmailSchema = termsAndConditionsSchema.extend({
  name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  email: z.string().email("Please provide a valid email address."),
});

const signUpPatientBaseSchema = z.object({
  pronouns: pronounsSchema,
  phone_number: userMobileSchema,
  address: patientAddressSchema,
  emergencyContact: emergencyContactSchema,
  password: passwordSchema,
});

const signUpNewPatientBaseSchema = z.object({
  phone_number: userMobileSchema,
  address: patientAddressSchema,
  password: passwordSchema,
});

export const newPatientDataSchema = signUpNewPatientBaseSchema.extend({
  type: z.literal(PATIENTS_TYPES.newPatient),
  namesAndEmail: namesAndEmailSchema,
});

export const patientDataSchema = signUpPatientBaseSchema.extend({
  type: z.literal(PATIENTS_TYPES.activePatient),
  termsAndConditions: termsAndConditionsSchema,
  names: namesSchema,
  pharmacy: pharmacySchema,
});

export const signUpPatientSchema = z.discriminatedUnion("type", [
  newPatientDataSchema,
  patientDataSchema,
]);

export type SignUpPatientSchemaType = z.infer<typeof signUpPatientSchema>;
export type NewPatientSchemaType = z.infer<typeof newPatientDataSchema>;
export type ActivePatientSchemaType = z.infer<typeof patientDataSchema>;

export const updatePatientDataSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  pronouns: pronounsSchema,
  birthday: z
    .string({ message: "Please provide your date of birth" })
    .refine((dateString) => isBefore(new Date(dateString), new Date()), {
      message: "Please enter a valid date of birth",
    }),
  phone_number: z
    .string()
    .min(1, "Mobile number required")
    .regex(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, {
      message: "Please enter mobile number with format: (123) 456-7890",
    }),
  addresses: z.array(patientAddressSchema),
  emergency_contacts: z.array(emergencyContactSchema),
});

export const loginUserSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Please enter a valid email address." }),
  password: z.string().min(1, { message: "Password is required" }),
});

export const emailSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Please enter a valid email address." }),
});

export type NamesSchemaType = z.infer<typeof namesSchema>;
export type NamesAndEmailSchemaType = z.infer<typeof namesAndEmailSchema>;
export type PronounsSchemaType = z.infer<typeof pronounsSchema>;
export type UserMobileSchemaType = z.infer<typeof userMobileSchema>;
export type AddressSchemaType = z.infer<typeof patientAddressSchema>;
export type EmergencyContactSchemaType = z.infer<typeof emergencyContactSchema>;
export type PatientSchemaType = z.infer<typeof patientDataSchema>;

import { twMerge } from "tailwind-merge";

import { SectionMenuOption } from "~/common/types";

interface SectionMenuProps {
  menuOptions: SectionMenuOption[];
}

export const SectionMenu = ({ menuOptions }: SectionMenuProps) => (
  <div className="flex h-full w-full flex-row justify-between gap-6 rounded-md bg-primary-50 px-3 py-2.5 shadow-md md:justify-normal">
    {menuOptions.map(
      ({ label, onClick, current, enable, icon }) =>
        enable && (
          <button
            onClick={onClick}
            key={label}
            className={twMerge(
              "flex gap-2 text-sm font-medium text-gray-35",
              current && "text-primary-600",
            )}
          >
            {label}
            {icon}
          </button>
        ),
    )}
  </div>
);

import { PlusIcon } from "~/common/components/icons";
import { CarePlanBoxLayout } from "./CarePlanBoxLayout";
import { ACTION_OPTIONS, ModalToOpen } from "./CarePlans";

interface AddNewCarePlanCard {
  setModalToOpen: (modalToOpen: ModalToOpen) => void;
}

export const AddNewCarePlanCard = ({ setModalToOpen }: AddNewCarePlanCard) => {
  return (
    <CarePlanBoxLayout>
      <button
        type="button"
        className="flex h-48 flex-col items-center justify-center gap-2"
        onClick={() =>
          setModalToOpen({ action: ACTION_OPTIONS.upsert, carePlan: undefined })
        }
      >
        <div className="rounded-full bg-gray-100 p-2">
          <PlusIcon className="fill-gray-100 text-gray-700" />
        </div>
        <p className="text-base font-medium text-gray-70">Add new care plan</p>
      </button>
    </CarePlanBoxLayout>
  );
};

import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ErrorText } from "~/common/components";

interface CheckBoxProps extends ComponentPropsWithoutRef<"input"> {
  id: string;
  children?: ReactNode;
  labelClassName?: string;
  error?: string;
  className?: string;
  containerClassName?: string;
  parentClassName?: string;
}

const CheckBox = React.forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    {
      className,
      children,
      id,
      labelClassName,
      containerClassName,
      parentClassName,
      error,
      ...props
    }: CheckBoxProps,
    ref,
  ) => {
    return (
      <div
        className={twMerge(
          "flex w-full flex-col gap-1 px-1 text-gray-700",
          parentClassName,
        )}
      >
        <div
          className={twMerge(
            "flex w-full items-center gap-2",
            containerClassName,
          )}
        >
          <input
            className={twMerge(
              "h-4 w-4 cursor-pointer rounded-md border-neutral-700 bg-slate-50",
              "checked:bg-primary-600 checked:hover:bg-primary-600",
              "focus:outline-none focus:ring-transparent focus:checked:bg-primary-600",
              className,
            )}
            id={id}
            {...props}
            ref={ref}
            type="checkbox"
          />
          {children && (
            <label
              htmlFor={id}
              className={twMerge(
                "cursor-pointer first-letter:uppercase",
                labelClassName,
              )}
            >
              {children}
            </label>
          )}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    );
  },
);

CheckBox.displayName = "CheckBox";
export default CheckBox;

import { FieldErrors, UseFormRegister } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import Input from "~/common/components/ui/Input";
import { PaymentSettingsFormValuesType } from "./ProviderSettingsForm";

interface DefaultConsultationFeeInputProps {
  isLoading: boolean;
  errors: FieldErrors<PaymentSettingsFormValuesType>;
  register: UseFormRegister<PaymentSettingsFormValuesType>;
}

const DefaultConsultationFeeInput = ({
  isLoading,
  errors,
  register,
}: DefaultConsultationFeeInputProps) => (
  <div className="flex flex-col gap-4">
    <p>
      The default fee patients will pay to proceed with the intake and schedule
      a live encounter. You will have the opportunity to modify this value for
      each patient consultation.
    </p>
    {isLoading ? (
      <Skeleton width={200} height={16} />
    ) : (
      <div className="flex gap-3">
        <Input
          type="currency"
          label="Default consultation fee"
          placeholder="Enter consultation fee"
          error={errors.default_consultation_fee?.message}
          {...register("default_consultation_fee", {
            setValueAs: (value) => Number(value),
          })}
        />
      </div>
    )}
  </div>
);

export default DefaultConsultationFeeInput;

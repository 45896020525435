import React from "react";

import { CancelSubmitButtons, WarningMessage } from "~/common/components";
import Modal from "~/common/components/Modal";
import { useDeleteCarePlan } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/myCarePlans/useDeleteCarePlan";
import { CarePlanWithPatients } from "~/providers/utils/types/carePlanTypes";

interface DeleteCarePlanModalProps {
  onClose: () => void;
  show: boolean;
  carePlan?: CarePlanWithPatients;
  handleDelete?: () => void;
}
export const DeleteCarePlanModal = ({
  onClose,
  carePlan,
  show,
}: DeleteCarePlanModalProps) => {
  const { deleteCarePlanMutation, isPending } = useDeleteCarePlan(onClose);
  const isDefaultCarePlanOrHasMembers =
    !!carePlan && (carePlan.patient_care_plans?.length || carePlan.default);

  const title = isDefaultCarePlanOrHasMembers
    ? "Cannot delete care plan"
    : "Delete care plan";

  return (
    <Modal title={title} show={show} onClose={onClose}>
      <div className="flex flex-row gap-2">
        {isDefaultCarePlanOrHasMembers ? (
          <WarningMessage className="text-base">
            You can’t delete your default care plan or plans with active
            members. Please assign all current members to a different plan
            before deleting.
          </WarningMessage>
        ) : (
          <div className="flex w-full flex-col gap-4">
            <p className="text-gray-700">
              Are you sure you want to delete {carePlan?.name}?
            </p>
            <div className="flex h-10 w-9/12 items-end self-end">
              <CancelSubmitButtons
                isSubmitting={isPending}
                handleSubmit={() => deleteCarePlanMutation(carePlan?.id ?? "")}
                submitButtonLabel="Delete Plan"
                containerClassName="size-full"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

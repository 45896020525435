import { IconProps } from "~/common/utils/types";

export const StarsIcon = ({ className, ...props }: IconProps) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M6.54738 5.5048C6.84029 4.89528 7.7104 4.90158 7.99445 5.51528L9.39797 8.54763C9.48756 8.74119 9.65074 8.89096 9.85125 8.96368L12.4948 9.92232C13.1624 10.1644 13.207 11.0917 12.5658 11.3968L9.77057 12.7268C9.59447 12.8106 9.45503 12.9558 9.37849 13.1352L8.00906 16.3445C7.73777 16.9802 6.84228 16.9959 6.54889 16.3701L5.05431 13.1818C4.96864 12.9991 4.81704 12.8554 4.62992 12.7798L1.8366 11.6499C1.16948 11.3801 1.17003 10.4354 1.83745 10.1663L4.61307 9.04737C4.79811 8.97277 4.94859 8.83173 5.03501 8.65191L6.54738 5.5048Z"
      fill="currentColor"
    />
    <path
      d="M13.1648 1.54044C13.4577 0.930924 14.3278 0.937227 14.6119 1.55093L15.1834 2.78564C15.273 2.9792 15.4361 3.12897 15.6367 3.20169L16.5262 3.52427C17.1938 3.76638 17.2385 4.6936 16.5972 4.99873L15.571 5.48702C15.3949 5.57081 15.2555 5.71605 15.1789 5.89543L14.6171 7.21204C14.3458 7.8478 13.4503 7.86349 13.1569 7.23763L12.5425 5.92705C12.4569 5.74429 12.3053 5.60067 12.1182 5.52499L11.1398 5.12927C10.4727 4.85944 10.4732 3.91473 11.1407 3.64566L12.1088 3.25537C12.2938 3.18078 12.4443 3.03973 12.5308 2.85991L13.1648 1.54044Z"
      fill="currentColor"
    />
  </svg>
);

import { useState } from "react";

import { H2, InformationIcon, SectionHeader } from "~/common/components";
import { PATIENT_OVERVIEW_KEYS, patientOverviewData } from "~/providers/utils";
import { PatientOverviewInfoModal } from "./PatientOverviewInfoModal";

interface PatientOverviewSignificantClinicalDimensionProps {
  potentialAreasOfConcern: string[];
  noSignsOfConcern: string[];
}

export const PatientOverviewSignificantClinicalDimension = ({
  potentialAreasOfConcern,
  noSignsOfConcern,
}: PatientOverviewSignificantClinicalDimensionProps) => {
  const [showInfoModal, setShowInfoModal] = useState(false);

  const { description, bottomDescription, options } =
    patientOverviewData[PATIENT_OVERVIEW_KEYS.clinical_dimensions];

  return (
    <>
      <PatientOverviewInfoModal
        show={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        title="Significant clinical dimensions"
        description={description}
        options={options}
        bottomDescription={bottomDescription}
      />
      <div className="flex flex-col gap-4 border-b-1.5 border-b-neutral-700 pb-9">
        <div className="flex items-center gap-2">
          <SectionHeader>Significant clinical dimensions</SectionHeader>
          <button onClick={() => setShowInfoModal(true)}>
            <InformationIcon className="size-4 text-gray-20" />
          </button>
        </div>
        <div className="flex flex-col gap-4 rounded-md bg-neutral-100 p-3">
          <H2 className="font-medium text-gray-70 md:text-lg">
            Potential areas of concern
          </H2>
          <div className="flex flex-wrap gap-2">
            {potentialAreasOfConcern.length
              ? potentialAreasOfConcern.map((item) => (
                  <span
                    key={item}
                    className="w-fit rounded-2xl bg-primary-200 px-2 py-1 text-primary-800"
                  >
                    {item}
                  </span>
                ))
              : "-"}
          </div>
        </div>
        <div className="flex flex-col gap-4 rounded-md bg-neutral-100 p-3">
          <H2 className="font-medium text-gray-70 md:text-lg">
            No signs of concern
          </H2>
          <div className="flex flex-wrap gap-2">
            {noSignsOfConcern.length
              ? noSignsOfConcern.map((item) => (
                  <span
                    key={item}
                    className="w-fit rounded-2xl bg-neutral-200 px-2 py-1"
                  >
                    {item}
                  </span>
                ))
              : "-"}
          </div>
        </div>
      </div>
    </>
  );
};

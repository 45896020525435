import { twMerge } from "tailwind-merge";

import { SectionHeader, ThumbIcon } from "~/common/components";
import { HandleRateSummaryProps } from "~/providers/utils/types/patientIntakeOverviewTypes";
import { SummaryType } from "~/providers/utils/types/patientOverviewTypes";

interface PatientOverviewTextInfoProps {
  title: string;
  info: string;
  summaryType: SummaryType;
  onRate: (data: HandleRateSummaryProps) => void;
  isLike?: boolean;
  showRateButtons?: boolean;
  isRatePending?: boolean;
}

export const PatientOverviewTextInfo = ({
  title,
  info,
  summaryType,
  onRate,
  isLike,
  showRateButtons = false,
  isRatePending = false,
}: PatientOverviewTextInfoProps) => (
  <div className="flex flex-col gap-4 border-b-1.5 border-b-neutral-700 pb-9">
    <SectionHeader>{title}</SectionHeader>
    <p className="text-sm text-gray-700">{info}</p>
    {showRateButtons && (
      <div className="flex flex-row gap-2">
        <button
          disabled={!!isLike || isRatePending}
          onClick={() => onRate({ isLike: true, summaryType })}
        >
          <ThumbIcon
            className={twMerge(
              "size-4",
              isLike && "fill-primary-500 text-primary-500",
            )}
          />
        </button>
        <button
          disabled={isLike === false || isRatePending}
          onClick={() => onRate({ isLike: false, summaryType })}
        >
          <ThumbIcon
            isDown
            className={twMerge(
              "size-4",
              isLike === false && "fill-primary-500 text-primary-500",
            )}
          />
        </button>
      </div>
    )}
  </div>
);

import {
  INTAKE_QUESTION_CODES,
  INTAKE_QUESTION_TYPES,
  YES_NO_TYPES,
} from "~/common/utils/consultations/intake";

export const LAST_MENSTRUAL_CYCLE_QUESTION_TITLE =
  "When was your last menstrual period?";

export const radioWithDateOptions = {
  [INTAKE_QUESTION_CODES.when_was_your_last_menstrual]: {
    title: "Do you experience a menstrual cycle?",
    type: INTAKE_QUESTION_TYPES.radio_with_date,
    options: [
      {
        value: YES_NO_TYPES.yes,
        asksExtraInfo: true,
        labelAsksExtraInfo: "When was your last menstrual period? ",
      },
      {
        value: YES_NO_TYPES.no,
      },
    ],
  },
} as const;

import { PATIENT_STATUS, TEAM_MEMBER_VALUES } from "~/common/constants";
import {
  HandleRateSummaryProps,
  UseGetPatientInteractionsActionsProps,
} from "~/providers/utils/types/patientIntakeOverviewTypes";
import { SUMMARY_TYPE } from "~/providers/utils/types/patientOverviewTypes";
import { useGetCurrentTeamMember } from "../auth";
import { useGetPatient } from "../useGetPatient";
import { useRateConsultationSummary } from "./useRateConsultationSummary";

export const useGetPatientInteractionsActions = ({
  patientId,
  screeningSummaryId,
  intakeSummaryId,
}: UseGetPatientInteractionsActionsProps) => {
  const { data: patient } = useGetPatient(patientId);

  const { data: teamMember } = useGetCurrentTeamMember();

  const summaryType = intakeSummaryId
    ? SUMMARY_TYPE.intake
    : SUMMARY_TYPE.screening;

  const summaryId = intakeSummaryId ?? screeningSummaryId;

  const showRateButtons =
    patient?.status !== PATIENT_STATUS.ongoing_care &&
    patient?.status !== PATIENT_STATUS.consultation_completed &&
    teamMember?.type === TEAM_MEMBER_VALUES.provider;

  const { rateSummaryMutation, isPending: isRatePending } =
    useRateConsultationSummary();

  const handleRateSummary = ({
    summaryType,
    isLike,
  }: HandleRateSummaryProps) => {
    rateSummaryMutation({
      summaryType,
      patientId,
      summaryId,
      isLike,
    });
  };

  return {
    isRatePending,
    showRateButtons,
    summaryType,
    handleRateSummary,
  };
};

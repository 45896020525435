export const RELATED_MODEL = {
  patient_medication: "patient_medication",
  patient_checkin: "patient_checkin",
  script_request: "script_request",
  patient_bundle: "patient_bundle",
  patient: "patient",
  patient_checkin_schedule: "patient_checkin_schedule",
  note: "note",
} as const;

export type RELATED_MODEL_TYPES = keyof typeof RELATED_MODEL;

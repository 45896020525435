import { OrderNoteIcon } from "~/common/components";
import { MedicationInteractionsIcon } from "~/common/components/icons/Orders/MedicationInteractionsIcon";
import { PrescriptionRequestIcon } from "~/common/components/icons/Orders/PrescriptionRequestIcon";
import { RELATED_MODEL } from "~/providers/constants";
import { ROUTES } from "~/providers/router";
import { getIconByCode, isBundleFeedContext } from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import { ActivityFeedCard } from "./ActivityFeedCard";
import { BundleActivityCard } from "./BundleActivityCard";
import { PatientCheckInActivityCard } from "./PatientCheckInActivityCard";

interface RenderActivityCardProps {
  feedOption: PatientFeed;
  setSelectedPatientInteractionId: (id: string) => void;
}

export const RenderActivityCard = ({
  feedOption,
  setSelectedPatientInteractionId,
}: RenderActivityCardProps) => {
  const { context } = feedOption;

  const patientScheduleIcon =
    !isBundleFeedContext(context) &&
    context.checkin_code &&
    getIconByCode(context.checkin_code)();

  const cardRenderer = {
    [RELATED_MODEL.patient_checkin_schedule]: (
      <ActivityFeedCard
        title={feedOption.description}
        date={feedOption.created_at}
        icon={patientScheduleIcon}
      />
    ),
    [RELATED_MODEL.patient_bundle]: (
      <BundleActivityCard patientActivity={feedOption} />
    ),
    [RELATED_MODEL.patient_medication]: !isBundleFeedContext(
      feedOption.context,
    ) && (
      <ActivityFeedCard
        key={feedOption.id}
        title={feedOption.description}
        icon={<MedicationInteractionsIcon />}
        date={feedOption.created_at}
        internalNote={feedOption.context.internal_note}
      />
    ),
    [RELATED_MODEL.script_request]: (
      <ActivityFeedCard
        key={feedOption.id}
        title={feedOption.description}
        date={feedOption.created_at}
        icon={<PrescriptionRequestIcon />}
        linkTo={`${ROUTES.prescriptionRequest}?id=${feedOption.related_model_id}`}
      />
    ),
    [RELATED_MODEL.note]: !isBundleFeedContext(feedOption.context) && (
      <ActivityFeedCard
        key={feedOption.id}
        title={feedOption.description}
        icon={<OrderNoteIcon />}
        date={feedOption.created_at}
        internalNote={feedOption.context.note}
      />
    ),
    [RELATED_MODEL.patient]: (
      <ActivityFeedCard
        key={feedOption.id}
        title={feedOption.description}
        date={feedOption.created_at}
      />
    ),
    [RELATED_MODEL.patient_checkin]: (
      <PatientCheckInActivityCard
        patientActivity={feedOption}
        setSelectedPatientInteractionId={setSelectedPatientInteractionId}
      />
    ),
  };

  return cardRenderer[feedOption.related_model_type];
};

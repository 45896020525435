import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ChevronIcon, Typography } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";
import { formatDate } from "~/providers/utils";

interface ActivityFeedCardProps extends ComponentPropsWithoutRef<"div"> {
  date: string;
  title: string;
  linkTo?: string;
  icon?: ReactNode;
  internalNote?: string;
  subtitle?: string;
}

export const ActivityFeedCard = ({
  date,
  internalNote,
  icon,
  title,
  className,
  subtitle,
  linkTo,
  ...props
}: ActivityFeedCardProps) => {
  const formattedDate = formatDate(date);

  const baseCard = (
    <div
      className={twMerge(
        linkTo &&
          "flex size-full rounded-lg border border-neutral-80 text-gray-70 hover:bg-primary-20 focus:bg-primary-50",
      )}
    >
      <div
        className={twMerge(
          "flex size-full flex-col gap-4 divide-y rounded-md border border-neutral-80 p-4 text-gray-70",
          linkTo ? "border-none" : className,
        )}
      >
        <div className="flex size-full items-center justify-between" {...props}>
          <div className="flex items-center gap-2">
            {icon}
            <Typography className="text-sm">
              {title}
              {subtitle && ` - ${subtitle}`}
            </Typography>
          </div>
          <Typography variant={TYPOGRAPHY_VARIANTS.light}>
            {formattedDate}
          </Typography>
        </div>
        {internalNote && (
          <div className="pt-4">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.italic}
              className="whitespace-pre-wrap break-all rounded-md bg-neutral-100 p-4"
            >
              {internalNote}
            </Typography>
          </div>
        )}
      </div>

      {linkTo && (
        <div className="flex items-center border-l border-neutral-80 px-2">
          <ChevronIcon
            direction={DIRECTIONS.right}
            className="h-3"
            strokeWidth={1}
          />
        </div>
      )}
    </div>
  );

  return linkTo ? (
    <Link target="_blank" to={linkTo}>
      {baseCard}
    </Link>
  ) : (
    baseCard
  );
};

import { CardInputsContainer } from "~/common/components";
import { useGetPaymentSettings } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useGetPaymentSettings";
import { CarePlans } from "./myCarePlans/CarePlans";
import { ProviderSettingsForm } from "./ProviderSettingsForm";

export const PaymentAndCarePlans = () => {
  const { data: providerSettings, isLoading } = useGetPaymentSettings();

  return (
    <div className="relative flex size-full flex-col items-center gap-6 text-gray-70 md:gap-8">
      <CardInputsContainer title="Intake Consultation fee / co-pay">
        <ProviderSettingsForm
          providerSettings={providerSettings}
          isLoadingProviderSettings={isLoading}
        />
      </CardInputsContainer>
      <CarePlans />
    </div>
  );
};

export const HopeIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#F5E1FF" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#E1A5FF" />
    <path
      d="M23.4434 19.5L14.6982 28.5M26.3585 16.5L28.3019 14.5M20.5284 16.5L18.585 14.5M28.3019 24.5L26.3585 22.5M23.4434 15.5V14.5M23.4434 25.5V24.5M27.3302 19.5H28.3019M17.6133 19.5H18.585"
      stroke="#BD80DA"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

import { useState } from "react";

import { InformationIcon, SectionHeader } from "~/common/components";
import { SCREENING_CODES } from "~/common/utils/consultations/screening";
import { PATIENT_OVERVIEW_KEYS, patientOverviewData } from "~/providers/utils";
import { ScoresData } from "~/providers/utils/types/patientOverviewTypes";
import { PatientOverviewInfoModal } from "./PatientOverviewInfoModal";
import { SelfReportedScoresCard } from "./SelfReportedScoresCard";

interface PatientOverviewSelfReportedScoresProps {
  scores: ScoresData;
}

export const PatientOverviewSelfReportedScores = ({
  scores,
}: PatientOverviewSelfReportedScoresProps) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { phq9, gad7, asrs } = scores;

  const { description, bottomDescription, options } =
    patientOverviewData[PATIENT_OVERVIEW_KEYS.self_reported];

  return (
    <>
      <PatientOverviewInfoModal
        show={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        title="Self-reported scores"
        description={description}
        options={options}
        bottomDescription={bottomDescription}
      />
      <div className="flex flex-col gap-4 pb-9">
        <div className="flex items-center gap-2">
          <SectionHeader>Self-reported scores</SectionHeader>
          <button onClick={() => setShowInfoModal(true)}>
            <InformationIcon className="size-4 text-gray-20" />
          </button>
        </div>
        <div className="flex gap-3">
          <SelfReportedScoresCard
            scoreData={phq9}
            code={SCREENING_CODES.phq9}
          />
          <SelfReportedScoresCard
            scoreData={gad7}
            code={SCREENING_CODES.gad7}
          />
          <SelfReportedScoresCard
            scoreData={asrs}
            code={SCREENING_CODES.asrs}
          />
        </div>
      </div>
    </>
  );
};

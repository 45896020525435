import React from "react";

import Modal from "~/common/components/Modal";
import {
  StripeCreateRestrictedApiKey,
  StripeNavigateApiKeys,
  StripeRetrieveYourPublishableKey,
} from "~/providers/assets";

interface StripeInfoModalProps {
  show: boolean;
  onClose: () => void;
}

export const StripeInfoModal = ({ show, onClose }: StripeInfoModalProps) => (
  <Modal
    className="h-fit w-96"
    titleClassName="text-lg font-medium w-2/3 text-gray-70"
    title="Setting Up Stripe for Live Payments"
    show={show}
    onClose={onClose}
  >
    <div className="h-96 overflow-scroll">
      <div className="flex flex-col items-start gap-10">
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            To begin processing live payments with Stripe, you will need two
            keys:
          </p>
          <ul className="list-disc pl-8 text-sm">
            <li>Publishable API Key</li>
            <li>Restricted API Key</li>
          </ul>
          <p className="text-sm">Before you begin, ensure: </p>
          <ul className="list-disc pl-8 text-sm">
            <li>
              Your Stripe account is set up with a completed business profile to
              accept live payments.
            </li>
            <li>
              Test mode is disabled (this is essential to process live
              transactions).
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Step 1: Login</h3>
          <p className="text-sm">Log in to your Stripe account.</p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Step 2: Access the API Keys</h3>
          <ul className="text-sm">
            <li>
              In your Stripe dashboard, click on the Developers tab in the top
              menu, and select <strong> API keys</strong>.
            </li>
          </ul>
          <img src={StripeNavigateApiKeys} alt="Stripe screenshot" />
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 3: Retrieve Your Publishable Key
          </h3>
          <p className="text-sm">
            Under the Standard Keys section, locate your Publishable Key.{" "}
          </p>
          <img src={StripeRetrieveYourPublishableKey} alt="Stripe screenshot" />
          <p className="text-sm">
            Copy this key, as you will need to input it into our platform.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 4: Create a Restricted API Key
          </h3>
          <p className="text-sm">
            Within the Restricted Keys section, click on Create Restricted Key.{" "}
          </p>
          <img src={StripeCreateRestrictedApiKey} alt="Stripe screenshot" />
          <p className="text-sm">
            For this key, assign permissions for the following resources:
          </p>
          <ul className="list-disc pl-8 text-sm">
            <li>
              <strong>Customers: WRITE</strong>
            </li>
            <li>
              <strong>Customer Sessions: READ</strong>
            </li>
            <li>
              <strong>PaymentIntents: WRITE</strong>
            </li>
            <li>
              <strong>
                Leave other resources at their default permissions.
              </strong>
            </li>
          </ul>
          <p className="text-sm">
            Click on Create Key at the bottom of the page.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 5: Finalize and Retrieve Your Key
          </h3>
          <ul className="list-disc pl-8 text-sm">
            <li>This newly created key is the Restricted API Key.</li>
            <li>Copy this key and input it into our platform.</li>
          </ul>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Verifying Your API Keys</h3>
          <p className="text-sm">
            After adding both keys into our platform, our system will
            automatically verify them by creating a test customer and a test
            payment intent in your Stripe account.
          </p>
        </div>

        <p className="text-sm">
          <strong>Note:</strong> If preferred, you can delete these test records
          afterward.
        </p>
      </div>
    </div>
  </Modal>
);

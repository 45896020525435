import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deleteFile } from "~/providers/api";

export const useDeleteFile = (handleSuccess: () => Promise<void>) => {
  const { mutate: deleteFileMutation, isPending } = useMutation({
    mutationFn: deleteFile,
    onError: () =>
      toast.error(
        "There was an issue eliminating the image. Please try again later.",
      ),
    onSuccess: async () => {
      toast.success("Image eliminated successfully.");
      await handleSuccess();
    },
  });

  return { deleteFileMutation, isPending };
};

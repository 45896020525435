import { api } from "~/providers/api";
import {
  PatientIntakeConsultationSummary,
  RateSummaryProps,
} from "../utils/types/patientIntakeOverviewTypes";
import {
  PatientScreeningConsultationSummary,
  SummaryFeedback,
} from "../utils/types/patientOverviewTypes";

const baseUrl = "/v1/team-members";

export const getPatientScreeningConsultationSummary = async (
  patientId: string,
) => {
  const { data } = await api.get<PatientScreeningConsultationSummary>(
    `${baseUrl}/patients/${patientId}/consultations/screening/summaries`,
  );
  return data;
};

export const getPatientIntakeConsultationSummary = async (
  patientId: string,
) => {
  const { data } = await api.get<PatientIntakeConsultationSummary>(
    `${baseUrl}/patients/${patientId}/consultations/intake/summaries`,
  );
  return data;
};

export const rateSummary = async ({
  summaryId,
  patientId,
  summaryType,
  isLike,
}: RateSummaryProps) => {
  const { data } = await api.post<SummaryFeedback>(
    `${baseUrl}/patients/${patientId}/consultations-summaries/${summaryId}/rate`,
    {
      is_like: isLike,
      consultation_type: summaryType,
    },
  );
  return data;
};

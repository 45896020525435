import React from "react";

import { SomethingWentWrong } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components";

export const ErrorBoundaryFallback = () => {
  return (
    <DescriptiveErrorLayout
      title="Something went wrong"
      description="Please refresh the page or try again later"
    >
      <img src={SomethingWentWrong} alt="Something went wrong" />
    </DescriptiveErrorLayout>
  );
};

import { replaceUnderscores } from "~/common/utils/replaceUnderscores";
import {
  MENTAL_STATUS_EXAMINATIONS_QUESTIONS,
  MENTAL_STATUS_EXAMINATIONS_VALUES,
} from "./questionData";

export const appearanceQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.well_groomed,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.well_groomed),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.disheveled,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.disheveled),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.appropriately_dressed,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.appropriately_dressed,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.inappropriately_dressed,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.inappropriately_dressed,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.age_appropriate,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.age_appropriate,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.younger_than_stated_age,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.younger_than_stated_age,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.older_than_stated_age,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.older_than_stated_age,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const behaviorQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.cooperative,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.cooperative),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.uncooperative,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.uncooperative),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.agitated,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.agitated),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.calm,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.calm),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.hyperactive,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.hyperactive),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.lethargic,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.lethargic),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.alert,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.alert),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const speechQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.normal_rate_and_rhythm,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.normal_rate_and_rhythm,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.normal_volume,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.normal_volume),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.pressured,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.pressured),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.slow,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.slow),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.soft,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.soft),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.loud,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.loud),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.monotone,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.monotone),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.articulate,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.articulate),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const moodQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.euthymic,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.euthymic),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.depressed,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.depressed),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.anxious,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.anxious),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.irritable,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.irritable),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.elevated,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.elevated),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.neutral,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.neutral),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const affectQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.congruent,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.congruent),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.incongruent,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.incongruent),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.flat,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.flat),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.blunted,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.blunted),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.restricted,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.restricted),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.labile,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.labile),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const thoughtProcessQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.linear,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.linear),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.tangential,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.tangential),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.circumstantial,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.circumstantial),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.disorganized,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.disorganized),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.flight_of_ideas,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.flight_of_ideas,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.goal_directed,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.goal_directed),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const thoughtContentQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.normal,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.normal),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.delusions,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.delusions),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.obsessions,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.obsessions),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.hallucinations,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.hallucinations),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.paranoid,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.paranoid),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.phobias,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.phobias),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.grandiosity,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.grandiosity),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const attentionAndConcentrationQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.focused,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.focused),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.distracted,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.distracted),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.hypervigilant,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.hypervigilant),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.impaired,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.impaired),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const judgmentAndInsightQuestionOptions = [
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.good,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.good),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.fair,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.fair),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.poor,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.poor),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.intact_insight,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.intact_insight),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.limited_insight,
    label: replaceUnderscores(
      MENTAL_STATUS_EXAMINATIONS_VALUES.limited_insight,
    ),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.no_insight,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.no_insight),
  },
  {
    value: MENTAL_STATUS_EXAMINATIONS_VALUES.other,
    label: replaceUnderscores(MENTAL_STATUS_EXAMINATIONS_VALUES.other),
    asksExtraInfo: true,
  },
];

export const mentalStatusExaminationsQuestions = [
  {
    title: "Appearance",
    key: "appearance",
    options: appearanceQuestionOptions,
  },
  {
    title: "Behavior",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.behavior,
    options: behaviorQuestionOptions,
  },
  {
    title: "Speech",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.speech,
    options: speechQuestionOptions,
  },
  {
    title: "Mood",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.mood,
    options: moodQuestionOptions,
  },
  {
    title: "Affect",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.affect,
    options: affectQuestionOptions,
  },
  {
    title: "Thought Process",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.thought_process,
    options: thoughtProcessQuestionOptions,
  },
  {
    title: "Thought Content",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.thought_content,
    options: thoughtContentQuestionOptions,
  },
  {
    title: "Attention and Concentration",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.attention_and_concentration,
    options: attentionAndConcentrationQuestionOptions,
  },
  {
    title: "Judgment and Insight",
    key: MENTAL_STATUS_EXAMINATIONS_QUESTIONS.judgment_and_insight,
    options: judgmentAndInsightQuestionOptions,
  },
];

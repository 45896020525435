import { env } from "~/patients/env";
import { Group, Option } from "../utils";

export const DIRECTIONS = {
  top: "top",
  right: "right",
  left: "left",
  down: "down",
} as const;

export const USER_TYPES = {
  provider: "provider",
  staff: "staff",
  patient: "patient",
};

export const OTHER_VALUE = "other" as const;
export const OTHER_OPTION = { label: "Other", value: OTHER_VALUE } as const;
export const RECURRENT = "recurrent";

export const APPOINTMENT_DURATIONS = {
  minutes_5: "5 min",
  minutes_10: "10 min",
  minutes_15: "15 min",
  minutes_20: "20 min",
  minutes_25: "25 min",
  minutes_30: "30 min",
} as const;

export const DEFAULT_PAGE_SIZE = "20" as const;
export const SCRIPTS_PAGE_SIZE = "20" as const;
export const ORDER = { ascendant: "asc", descendant: "desc" } as const;
export const ORDER_BY = {
  creationDate: "created_at",
  completedDate: "completed_at",
  lastName: "last_name",
  genericName: "generic_name",
} as const;

export const TEAM_MEMBER_VALUES = {
  provider: "provider",
  staff: "staff",
} as const;

export const BUTTON_SIZES = {
  sm: "sm",
  md: "md",
  lg: "lg",
} as const;

export const ANIMATION_DURATIONS = {
  fast: 200,
  normal: 300,
  moderate: 400,
  slow: 500,
} as const;

export const LEAD_PATIENT_STATUS = {
  consultation_requested: "consultation_requested",
  screening_started: "screening_started",
  screening_submitted: "screening_submitted",
  referred_after_screening: "referred_after_screening",
  screening_completed: "screening_completed",
  intake_started: "intake_started",
  intake_submitted: "intake_submitted",
  appointment_scheduled: "appointment_scheduled",
  consultation_completed: "consultation_completed",
} as const;

export type LeadPatientStatus =
  (typeof LEAD_PATIENT_STATUS)[keyof typeof LEAD_PATIENT_STATUS];

export const PATIENT_STATUS = {
  ...LEAD_PATIENT_STATUS,
  ongoing_care: "ongoing_care",
} as const;

export const leadPatientStatusOptions: Option[] = Object.values(
  LEAD_PATIENT_STATUS,
).map((status) => ({
  value: status,
  label: status.replace(/_/g, " "),
}));

export const patientStatusOptions: Option[] = Object.values(PATIENT_STATUS).map(
  (status) => ({
    value: status,
    label: status.replace(/_/g, " "),
  }),
);

export const statusGroupOptions: Group[] = [
  {
    group: "Status",
    groupOptions: leadPatientStatusOptions,
  },
] as const;

export const AUDIO_MIN_DURATION = Number(env.VITE_AUDIO_MIN_DURATION);

export const AUDIO_MAX_DURATION = Number(env.VITE_AUDIO_MAX_DURATION);

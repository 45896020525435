import { Group, Option } from "~/common/utils";

export const generatePrescriptionOwnerOptions = (
  groupName: string,
  options: Option[],
): Group[] => [
  {
    group: groupName,
    groupOptions: options,
  },
];

import { replaceUnderscores } from "~/common/utils";
import {
  RegisterKeys,
  SYMPTOMS_INPUT_TYPES,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";
import {
  findAnswerOfSymptom,
  getFormOfSelectedSymptom,
  getSleepIssuesAnswersWithKeys,
  sleepIssuesSymptomsBaseKey,
  symptomsCheckedToString,
} from "~/providers/utils/consultationRequests";

export const formatSymptomDetailsAnswer = (
  symptom: RegisterKeys,
  answer: SymptomsFormValues,
) => {
  if (symptom.startsWith(`${sleepIssuesSymptomsBaseKey}.`))
    return getSleepIssuesAnswersWithKeys(answer)[symptom];

  const symptomAnswer = findAnswerOfSymptom(answer, symptom);
  if (!symptomAnswer) return;
  const symptomsForm = getFormOfSelectedSymptom(symptom);
  const symptomsExtraDataForms = symptomsForm?.extra_data?.children;

  const hasMultipleChoiceAnswer = symptomsExtraDataForms?.some(
    (child) => child.type === SYMPTOMS_INPUT_TYPES.check,
  );
  const hasTextAnswer = symptomsExtraDataForms?.some(
    (child) => child.type === SYMPTOMS_INPUT_TYPES.text,
  );

  if (hasMultipleChoiceAnswer && symptomsExtraDataForms)
    return symptomsCheckedToString(
      symptom,
      symptomAnswer,
      symptomsExtraDataForms,
    );

  if (!("extra_data" in symptomAnswer)) return "";
  return hasTextAnswer
    ? symptomAnswer.extra_data
    : symptomAnswer.extra_data && replaceUnderscores(symptomAnswer.extra_data);
};

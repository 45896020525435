import Skeleton from "react-loading-skeleton";

interface LoadingPatientsTableProps {
  length?: number;
}

export const LoadingPatientsTable = ({
  length = 3,
}: LoadingPatientsTableProps) => {
  const array = Array.from({ length }, (_, index) => index + 1);
  return (
    <tbody className="text-[0.94rem] text-gray-700">
      {array.map((_, index) => (
        <tr className="border-b border-gray-300" key={index}>
          <td className="px-6 py-5" colSpan={3}>
            {" "}
            <Skeleton />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

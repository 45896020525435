import { SectionHeader } from "~/common/components";
import { typedEntries } from "~/common/utils";
import { FunctionalDomains } from "~/providers/utils/types/patientIntakeOverviewTypes";
import { FunctionalDomainCard } from "./FunctionalDomainCard";

interface PatientOverviewFunctionalDomainsProps {
  functionalDomains: FunctionalDomains;
}

export const PatientOverviewFunctionalDomains = ({
  functionalDomains,
}: PatientOverviewFunctionalDomainsProps) => {
  return (
    <div className="flex flex-col gap-4 border-b-1.5 border-b-neutral-700 pb-9">
      <SectionHeader>Functional domains</SectionHeader>

      {typedEntries(functionalDomains).map(([key, domain]) => (
        <FunctionalDomainCard key={key} value={key} domain={domain} />
      ))}
    </div>
  );
};

import { Fragment, PropsWithChildren } from "react";

import { TableData } from "~/common/components";

interface RawResponseLayout extends PropsWithChildren {
  question?: string;
}

export const RawResponseLayout = ({
  question,
  children,
}: RawResponseLayout) => {
  if (!question) return null;
  return (
    <tr className="border-b border-gray-300 py-4 last:border-none">
      <Fragment>
        <TableData className="px-4 first-letter:uppercase">
          {question}
        </TableData>
        <TableData className="px-4 first-letter:uppercase">
          {children}
        </TableData>
      </Fragment>
    </tr>
  );
};

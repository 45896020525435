import { useQuery } from "@tanstack/react-query";

import { getPatientScreeningConsultationSummary } from "~/providers/api";

export const useGetPatientScreeningConsultationSummary = (
  patientId: string,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientScreeningConsultationSummary(patientId),
    queryKey: ["getPatientScreeningConsultationSummary", patientId],
  });
  return { data, isLoading };
};

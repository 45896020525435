import { useQuery } from "@tanstack/react-query";

import { TEAM_MEMBER_VALUES } from "~/common/constants";
import { getPaymentSettings } from "~/providers/api/paymentSettings";
import { useGetCurrentTeamMember } from "./auth";

export const useGetPaymentSettings = () => {
  const { data: teamMember } = useGetCurrentTeamMember();

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => getPaymentSettings(),
    queryKey: ["getPaymentSettings"],
    enabled: !!teamMember && teamMember.type === TEAM_MEMBER_VALUES.provider,
  });
  return { data, isLoading, isFetching, refetch };
};

import { FieldErrors, UseFormRegister } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import { ErrorText, InformationIcon } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { PaymentSettingsFormValuesType } from "./ProviderSettingsForm";

interface StripeIntegrationSectionProps {
  isLoading: boolean;
  errors: FieldErrors<PaymentSettingsFormValuesType>;
  validationErrorDetail: string | null;
  register: UseFormRegister<PaymentSettingsFormValuesType>;
  onShowInstructions: () => void;
}

const StripeIntegrationSection = ({
  isLoading,
  errors,
  validationErrorDetail,
  register,
  onShowInstructions,
}: StripeIntegrationSectionProps) => (
  <div className="flex flex-col gap-2">
    <div className="flex items-center gap-4">
      <span className="text-lg font-medium">Stripe integration</span>
      <button onClick={onShowInstructions}>
        <InformationIcon />
      </button>
    </div>
    <p>
      To receive payments for consultation fees, you must set up a Stripe
      account. Enter your API keys below to activate the integration.
    </p>

    {isLoading ? (
      <Skeleton width={200} height={16} />
    ) : (
      <>
        <div className="flex gap-3">
          <Input
            label="Stripe publishable key"
            placeholder="Enter publishable key"
            error={errors.publishable_key?.message}
            isError={Boolean(validationErrorDetail)}
            {...register("publishable_key")}
          />
          <Input
            label="Stripe restricted API key"
            placeholder="Enter API key"
            error={errors.secret_key?.message}
            isError={Boolean(validationErrorDetail)}
            {...register("secret_key")}
            type="password"
          />
        </div>
        {validationErrorDetail && (
          <ErrorText className="text-sm text-error">
            {validationErrorDetail}
          </ErrorText>
        )}
      </>
    )}
  </div>
);

export default StripeIntegrationSection;

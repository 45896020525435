export const MoodIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#D0F9D9" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#47D065" />
    <path
      d="M21.1248 16.1249H21.8748M21.1248 16.8749H21.8748M15.5 18.5C16.1667 19.1667 18.3 20.5 21.5 20.5C24.7 20.5 26.8333 19.1667 27.5 18.5M19.5 20.5L18.5011 28.4908C18.5011 28.4913 18.5014 28.4917 18.5018 28.4919L18.502 28.4919C18.5025 28.4921 18.503 28.4919 18.5032 28.4914C19.0056 27.1552 20.3026 24.5 21.5 24.5C22.6974 24.5 23.9944 27.1552 24.4968 28.4914C24.497 28.4919 24.4975 28.4921 24.498 28.4919L24.4982 28.4919C24.4986 28.4917 24.4989 28.4913 24.4989 28.4908L23.5 20.5M22.5 16.5C22.5 17.0523 22.0523 17.5 21.5 17.5C20.9477 17.5 20.5 17.0523 20.5 16.5C20.5 15.9477 20.9477 15.5 21.5 15.5C22.0523 15.5 22.5 15.9477 22.5 16.5ZM32.5 21.5C32.5 27.5751 27.5751 32.5 21.5 32.5C15.4249 32.5 10.5 27.5751 10.5 21.5C10.5 15.4249 15.4249 10.5 21.5 10.5C27.5751 10.5 32.5 15.4249 32.5 21.5Z"
      stroke="#2AA63C"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

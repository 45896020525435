export const WorkIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#D6EDF8" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#86C9EB" />
    <path
      d="M17.6133 17.5V16.5C17.6133 14.2909 19.3535 12.5 21.5001 12.5C23.6467 12.5 25.3868 14.2909 25.3868 16.5V17.5M12.7549 21.5L20.7108 25.139C21.2133 25.3688 21.7869 25.3688 22.2894 25.139L30.2453 21.5M15.8643 30.5H27.1359C28.2243 30.5 28.7685 30.5 29.1842 30.282C29.5498 30.0903 29.8471 29.7843 30.0334 29.408C30.2453 28.9802 30.2453 28.4201 30.2453 27.3V20.7C30.2453 19.5799 30.2453 19.0198 30.0334 18.592C29.8471 18.2157 29.5498 17.9097 29.1842 17.718C28.7685 17.5 28.2243 17.5 27.1359 17.5H15.8643C14.7759 17.5 14.2317 17.5 13.816 17.718C13.4503 17.9097 13.153 18.2157 12.9667 18.592C12.7549 19.0198 12.7549 19.5799 12.7549 20.7V27.3C12.7549 28.4201 12.7549 28.9802 12.9667 29.408C13.153 29.7843 13.4503 30.0903 13.816 30.282C14.2317 30.5 14.7759 30.5 15.8643 30.5Z"
      stroke="#61A5C6"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deleteSchedule } from "~/providers/api/patientProfileService";

interface DeleteOrderAndRelatedParams {
  patientId: string;
  scheduleId?: string;
  onSuccess: () => void;
}

export const useDeleteSchedule = ({
  patientId,
  scheduleId,
  onSuccess,
}: DeleteOrderAndRelatedParams) => {
  const queryClient = useQueryClient();

  const { mutate: deleteScheduleMutation, isPending } = useMutation({
    mutationFn: () => deleteSchedule(patientId, scheduleId ?? ""),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatientSchedules"],
      });
      onSuccess();
    },
    onError: () =>
      toast.error("We had trouble deleting this order, try again later."),
  });

  return {
    deleteScheduleMutation,
    isPending,
  };
};

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "~/common/components";
import { StripeInfoModal } from "~/providers/components/StripeInfoModal";
import { useUpdatePaymentSettings } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useUpdatePaymentSettings";
import { paymentSettingsSchema } from "~/providers/utils/paymentAndCarePlansSchemas";
import { ProviderPaymentSettings } from "~/providers/utils/types/paymentSettingsTypes";
import DefaultConsultationFeeInput from "./DefaultConsultationFeeInput";
import StripeIntegrationSection from "./StripeIntegrationSection";

export type PaymentSettingsFormValuesType = z.infer<
  typeof paymentSettingsSchema
>;

interface ProviderSettingsFormProps {
  providerSettings?: ProviderPaymentSettings;
  isLoadingProviderSettings: boolean;
}

export const ProviderSettingsForm = ({
  providerSettings,
  isLoadingProviderSettings,
}: ProviderSettingsFormProps) => {
  const { updatePaymentSettingsMutation, isPending, validationErrorDetail } =
    useUpdatePaymentSettings();

  const [showInstructionsModal, setShowInstructionsModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PaymentSettingsFormValuesType>({
    resolver: zodResolver(paymentSettingsSchema),
    values: {
      secret_key: providerSettings?.secret_key ?? "",
      publishable_key: providerSettings?.publishable_key ?? "",
      default_consultation_fee:
        providerSettings?.default_consultation_fee ?? null,
    },
  });

  const onSubmit: SubmitHandler<PaymentSettingsFormValuesType> = (data) => {
    updatePaymentSettingsMutation({
      updatePaymentSettingsData: data,
      providerPaymentSettingsId: providerSettings?.id ?? "",
    });
  };

  return (
    <>
      <StripeInfoModal
        show={showInstructionsModal}
        onClose={() => setShowInstructionsModal(false)}
      />
      <form
        className="flex size-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DefaultConsultationFeeInput
          isLoading={isLoadingProviderSettings}
          errors={errors}
          register={register}
        />

        <StripeIntegrationSection
          isLoading={isLoadingProviderSettings}
          errors={errors}
          validationErrorDetail={validationErrorDetail}
          register={register}
          onShowInstructions={() => setShowInstructionsModal(true)}
        />

        <Button
          disabled={isPending || !isDirty}
          type="submit"
          size="sm"
          isLoading={isPending}
          className="h-11 w-36 self-end"
        >
          Save changes
        </Button>
      </form>
    </>
  );
};

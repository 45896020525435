import {
  BaseCarePlanData,
  CarePlanWithPatients,
  CreateCarePlanResponse,
  PatientCarePlansList,
  UpdateCarePlan,
} from "../utils/types/carePlanTypes";
import {
  CreatePatientCarePlanRequest,
  ProviderPaymentSettings,
  ProviderPaymentSettingsUpdate,
} from "../utils/types/paymentSettingsTypes";
import { api } from "./axios";

const baseUrl = "/v1/team-members";

export const updatePaymentSettings = async ({
  updatePaymentSettingsData,
  providerPaymentSettingsId,
}: ProviderPaymentSettingsUpdate) => {
  const { data } = await api.patch<ProviderPaymentSettings>(
    `${baseUrl}/payment-settings/${providerPaymentSettingsId}`,
    updatePaymentSettingsData,
  );
  return data;
};

export const getPaymentSettings = async () => {
  const { data } = await api.get<ProviderPaymentSettings>(
    `${baseUrl}/payment-settings/`,
  );
  return data;
};

export const createCarePlan = async (payload: BaseCarePlanData) => {
  const { data } = await api.post<CreateCarePlanResponse>(
    `${baseUrl}/care-plans`,
    payload,
  );
  return data;
};

export const updateCarePlan = async (payload: UpdateCarePlan) => {
  const { data } = await api.patch<CreateCarePlanResponse>(
    `${baseUrl}/care-plans/${payload.id}`,
    payload,
  );
  return data;
};

export const listCarePlans = async (withPatients: boolean) => {
  const { data } = await api.get<CarePlanWithPatients[]>(
    `${baseUrl}/care-plans`,
    {
      params: {
        with_patients: withPatients,
      },
    },
  );
  return data;
};

export const deleteCarePlan = async (id: string) => {
  const { data } = await api.delete<CreateCarePlanResponse>(
    `${baseUrl}/care-plans/${id}`,
  );
  return data;
};

export const getPatientCarePlansList = async (patientId?: string) => {
  const { data } = await api.get<PatientCarePlansList[]>(
    `${baseUrl}/patients/${patientId}/care-plans`,
  );

  return data;
};

export const createPatientCarePlan = async ({
  carePlanId,
  patientId,
}: CreatePatientCarePlanRequest) => {
  const { data } = await api.post<PatientCarePlansList>(
    `${baseUrl}/patients/${patientId}/care-plans`,
    { provider_care_plan_id: carePlanId },
  );

  return data;
};

import { replaceUnderscores } from "~/common/utils";
import { functionalDomainIcon } from "~/providers/utils";
import {
  FunctionalDomain,
  FunctionalDomainKeys,
} from "~/providers/utils/types/patientIntakeOverviewTypes";
import { FunctionalDomainCardRow } from "./FunctionalDomainCardRow";

interface FunctionalDomainCardProps {
  value: FunctionalDomainKeys;
  domain?: FunctionalDomain;
}

export const FunctionalDomainCard = ({
  value,
  domain,
}: FunctionalDomainCardProps) => {
  if (!domain?.sentence_completions.classification) return null;

  const {
    sentence_completions: sentenceCompletions,
    classification,
    direct_answers: directAnswers,
    narrative,
  } = domain;

  return (
    <div className="flex flex-col gap-4 rounded-xl border p-4">
      <div className="flex flex-row gap-3">
        {functionalDomainIcon[value]}
        <div>
          <h3 className="-mt-1 text-lg font-semibold text-gray-700 first-letter:uppercase">
            {replaceUnderscores(value)}
          </h3>
          <p className="font-medium text-gray-60">{classification}</p>
        </div>
      </div>

      {directAnswers && (
        <FunctionalDomainCardRow
          title="Direct answer:"
          classification={directAnswers}
        />
      )}

      <FunctionalDomainCardRow
        title="Sentence completion:"
        classification={sentenceCompletions.classification}
        sentences={sentenceCompletions.sentences.join(", ")}
      />

      {!!narrative?.length && (
        <FunctionalDomainCardRow
          title="Audio response"
          sentences={narrative.map((sentence) => `"${sentence}"`).join(", ")}
        />
      )}
    </div>
  );
};

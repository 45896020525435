import { AUDIO_MAX_DURATION, AUDIO_MIN_DURATION } from "~/common/constants";
import {
  AudioQuestionData,
  CONSULTATIONS_QUESTION_TYPES,
} from "~/common/utils/consultations/shared";
import { INTAKE_QUESTION_CODES } from "../intakeQuestionsData";

export const DEFAULT_AUDIO_DURATIONS = {
  min: AUDIO_MIN_DURATION,
  max: AUDIO_MAX_DURATION,
} as const;

interface AudioQuestionParams {
  title: string;
  minDuration?: number;
  maxDuration?: number;
}

const createAudioQuestion = ({
  title,
  minDuration = DEFAULT_AUDIO_DURATIONS.min,
  maxDuration = DEFAULT_AUDIO_DURATIONS.max,
}: AudioQuestionParams): AudioQuestionData => ({
  title,
  type: CONSULTATIONS_QUESTION_TYPES.audio,
  minDuration,
  maxDuration,
});

export const audioQuestions = {
  [INTAKE_QUESTION_CODES.first_noticed_symptoms]: createAudioQuestion({
    title:
      "When you first noticed symptoms is very important. Try to think back to when your symptoms began, do you remember approximately when? What was going on in your life around that time?",
  }),
  [INTAKE_QUESTION_CODES.anything_else_symptoms]: createAudioQuestion({
    title: "Anything else about your symptoms you think is important?",
  }),
  [INTAKE_QUESTION_CODES.what_is_the_first_memory]: createAudioQuestion({
    title:
      "Think back to the first thing you can remember in your life. There should be a specific memory that comes to mind. What is that specific memory?",
  }),
  [INTAKE_QUESTION_CODES.how_are_you_feeling_within_the_memory]:
    createAudioQuestion({
      title: "How are you feeling within the memory?",
    }),
  [INTAKE_QUESTION_CODES.when_you_think_of_the_memory_now]: createAudioQuestion(
    {
      title: "When you think of the memory now, what thoughts come to mind?",
    },
  ),
  [INTAKE_QUESTION_CODES.what_was_your_childhood_like]: createAudioQuestion({
    title: "What was your childhood like? Where did you grow up?",
  }),
  [INTAKE_QUESTION_CODES.most_important_family_members_in_your_life]:
    createAudioQuestion({
      title:
        "Who are the most important family members in your life? What is your relationship like with them?",
    }),
  [INTAKE_QUESTION_CODES.can_you_tell_me_about_your_mother]:
    createAudioQuestion({
      title:
        "Can you tell me about your mother? What was her personality like, and how would you describe your relationship with her?",
    }),
  [INTAKE_QUESTION_CODES.can_you_tell_me_about_your_father]:
    createAudioQuestion({
      title:
        "Can you tell me about your father? What was his personality like, and how would you describe your relationship with him?",
    }),
  [INTAKE_QUESTION_CODES.your_early_life]: createAudioQuestion({
    title:
      "Tell me a little bit about your early life. Do you have any siblings? What did/do your parents do for a living?",
  }),
  [INTAKE_QUESTION_CODES.best_qualities]: createAudioQuestion({
    title: "What are your best qualities?",
  }),
  [INTAKE_QUESTION_CODES.most_important_things_in_life]: createAudioQuestion({
    title:
      "What are some of the things that are most important to you in your life?",
  }),
} as const;

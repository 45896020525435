import { UseFormRegisterReturn } from "react-hook-form";

import { TransitionEffect } from "~/common/components";
import QuestionaryCheckBox from "~/common/components/ui/QuestionaryCheckBox";
import TextArea from "~/common/components/ui/Textarea";
import { MultiCheckOptions } from "~/common/utils/consultations/shared";
import { MultiCheckMentalStatusOptions } from "~/providers/utils/mentalStatusExaminations/interfaces";

export interface MultiCheckOptionProps {
  option: MultiCheckMentalStatusOptions | MultiCheckOptions;
  register: (index: number) => UseFormRegisterReturn<string>;
  id: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  selectedIndex: number;
  otherErrorMessage?: string;
}

export const MultiCheckOption = ({
  option,
  register,
  onChange,
  selectedIndex,
  id,
  otherErrorMessage,
}: MultiCheckOptionProps) => {
  const isSelected = selectedIndex !== -1;

  return (
    <div className="flex flex-col gap-2">
      {option.title && (
        <h3 className="pt-2 text-lg font-medium leading-5">{option.title}</h3>
      )}
      <QuestionaryCheckBox
        id={id}
        value={option.value}
        checked={isSelected}
        onChange={onChange}
      >
        <span className="first-letter:capitalize">{option.label}</span>
      </QuestionaryCheckBox>

      {isSelected && option.asksExtraInfo && (
        <TransitionEffect>
          <TextArea
            rows={4}
            error={otherErrorMessage}
            placeholder="Enter further description"
            className="text-base font-normal placeholder:text-base placeholder:not-italic placeholder:text-gray-60"
            {...register(selectedIndex)}
          />
        </TransitionEffect>
      )}
    </div>
  );
};

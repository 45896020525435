import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import { statusGroupOptions } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";
import SearchBar from "../SearchBar";

interface ConsultationRequestFiltersProps {
  isLoading: boolean;
  disableFilters: boolean;
}

export const ConsultationRequestFilters = ({
  isLoading,
  disableFilters,
}: ConsultationRequestFiltersProps) => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;
  const [patientFilter, setPatientFilter] = useState(paramName);

  const debouncedName = useDebounce(patientFilter, 600);

  useEffect(() => {
    const newParams = new URLSearchParams(params);
    if (debouncedName) {
      newParams.delete(PATIENT_FILTER_PARAMS.page);
      newParams.set(PATIENT_FILTER_PARAMS.name, debouncedName);
    } else {
      newParams.delete(PATIENT_FILTER_PARAMS.name);
    }
    setParams(newParams);
  }, [debouncedName, params, setParams]);

  const { handleMultipleFilters } = useHandleMultipleFilters();

  return (
    <div className="grid grid-cols-3">
      <SectionHeader className="flex items-center text-xl font-semibold lg:text-3xl">
        Consultation requests
      </SectionHeader>
      <SearchBar
        className="w-full"
        disabled={disableFilters}
        placeholder="Search by patient name"
        isLoading={isLoading}
        value={patientFilter}
        setValue={setPatientFilter}
      />
      <div className="flex items-center justify-end">
        <MultiSelectGroup
          name="status"
          placeholder="Status"
          hasSelectAll={false}
          selectNoneLabel="Clear all"
          disabled={disableFilters}
          options={statusGroupOptions}
          value={params.getAll(PATIENT_FILTER_PARAMS.consultationStatus)}
          onChange={(filters) =>
            handleMultipleFilters(
              PATIENT_FILTER_PARAMS.consultationStatus,
              filters,
            )
          }
          containerClassName="w-60"
        />
      </div>
    </div>
  );
};

import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import {
  ConsultationCompletionDetails,
  PatientCompleteAndSignForm,
} from "~/providers/components/patientProfile";
import {
  useGetConsultationCompletionDetails,
  useGetPatient,
} from "~/providers/hooks";
import { PatientParams } from "~/providers/utils";

export const PatientCompleteAndSign = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patientData } = useGetPatient(patientId);

  const { data: completedData, isLoading } =
    useGetConsultationCompletionDetails(patientId);

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  if (patientData?.status === PATIENT_STATUS.ongoing_care && !completedData)
    return null;

  if (
    (patientData?.status === PATIENT_STATUS.ongoing_care ||
      patientData?.status === PATIENT_STATUS.consultation_completed) &&
    completedData
  )
    return <ConsultationCompletionDetails details={completedData} />;

  return (
    <PatientCompleteAndSignForm
      patientId={patientId}
      completedData={completedData}
    />
  );
};

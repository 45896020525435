import { useState } from "react";

import { Table } from "~/common/components/table";
import { TableHead } from "~/common/components/table/TableHead";
import { TableHeader } from "~/common/components/table/TableHeader";
import { PatientMedicationDetail } from "~/common/types";
import {
  formatDate,
  generateFrequency,
  generateStrength,
} from "~/providers/utils";
import { MedicationDetailsDrawer } from "./detailsDrawer";
import { MedicationsTableRow } from "./MedicationsTableRow";

interface MedicationsTableProps {
  patientMedicationsDetails: PatientMedicationDetail[];
  medicationName: string;
}

export const MedicationsTable = ({
  medicationName,
  patientMedicationsDetails,
}: MedicationsTableProps) => {
  const [selectedMedication, setSelectedMedication] = useState<
    PatientMedicationDetail | undefined
  >();

  return (
    <div className="w-full overflow-auto rounded-xl border border-gray-300">
      <Table>
        <TableHead className="border-neutral-700 bg-neutral-25">
          <tr>
            <TableHeader>Date</TableHeader>
            <TableHeader>Strength</TableHeader>
            <TableHeader>Take</TableHeader>
            <TableHeader>Frequency</TableHeader>
            <TableHeader />
          </tr>
        </TableHead>
        <tbody className="bg-white text-sm text-gray-70">
          {patientMedicationsDetails.map((detail) => (
            <MedicationsTableRow
              key={detail.id}
              date={formatDate(detail.start_date)}
              strength={generateStrength(detail.medication_strength)}
              take={detail.take}
              frequency={generateFrequency(detail)}
              handleSeeDetails={() => setSelectedMedication(detail)}
            />
          ))}
        </tbody>
      </Table>
      <MedicationDetailsDrawer
        medication={selectedMedication}
        medicationName={medicationName}
        handleClose={() => setSelectedMedication(undefined)}
      />
    </div>
  );
};

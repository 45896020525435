export const MoneyIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#FDEED1" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#FBCD75" />
    <path
      d="M26.3585 17.5C26.3585 15.2909 24.6183 13.5 22.4717 13.5H20.5284C18.3818 13.5 16.6416 15.2909 16.6416 17.5C16.6416 19.7091 18.3818 21.5 20.5284 21.5H22.4717C24.6183 21.5 26.3585 23.2909 26.3585 25.5C26.3585 27.7091 24.6183 29.5 22.4717 29.5H20.5284C18.3818 29.5 16.6416 27.7091 16.6416 25.5M21.5 11.5L21.5 31.5"
      stroke="#D78100"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

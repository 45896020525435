import { twMerge } from "tailwind-merge";

import { Tag } from "~/common/components";
import {
  ENGAGEMENT_TYPES,
  EngagementTypes,
} from "~/providers/constants/patientSearchConstants";

interface PatientEngagementTagProps {
  engagement: EngagementTypes;
}

export const PatientEngagementTag = ({
  engagement,
}: PatientEngagementTagProps) => {
  return (
    <Tag
      className={twMerge(
        "inline px-3",
        engagement === ENGAGEMENT_TYPES.active &&
          "bg-primary-200 text-primary-800",
        engagement === ENGAGEMENT_TYPES.inactive && "bg-neutral-5 text-gray-70",
      )}
    >
      <span className="capitalize">{engagement}</span>
    </Tag>
  );
};

import { replaceUnderscores } from "~/common/utils";
import {
  CompletedMultiCheckAnswer,
  MENTAL_STATUS_EXAMINATIONS_VALUES,
} from "~/providers/utils/mentalStatusExaminations";

export const formatMentalStatusExaminationsAnswers = (
  answer: CompletedMultiCheckAnswer | null,
) => {
  if (!answer) {
    return ["No information provided"];
  }

  return [
    ...(answer.values?.map(({ value, extra_data }) =>
      value === MENTAL_STATUS_EXAMINATIONS_VALUES.other && extra_data
        ? `${replaceUnderscores(value)}: ${extra_data}`
        : replaceUnderscores(value),
    ) ?? []),
    ...(answer.extra_data ? [answer.extra_data] : []),
  ];
};

import { PropsWithChildren } from "react";

import { Button } from "../ui/button";
import ErrorLayout from "./ErrorLayout";

interface DescriptiveErrorLayoutProps extends PropsWithChildren {
  handleBackToHome?: () => void;
  errorType?: string;
  title: string;
  description: string;
  buttonText?: string;
}
export const DescriptiveErrorLayout = ({
  children,
  errorType,
  title,
  description,
  handleBackToHome,
  buttonText = "Back to Home",
}: DescriptiveErrorLayoutProps) => (
  <ErrorLayout>
    <div className="flex h-full flex-col items-center justify-center gap-6">
      {children}
      <div className="flex w-full flex-col items-center gap-9">
        <div className="flex w-full flex-col items-center gap-4">
          {errorType && (
            <h1 className="font-outfit text-7xl font-semibold">{errorType}</h1>
          )}
          <div className="flex w-full flex-col items-center gap-2">
            <h2 className="font-outfit text-2xl font-semibold">{title}</h2>
            <p className="w-3/4 text-center">{description}</p>
          </div>
        </div>
        {handleBackToHome && (
          <Button onClick={handleBackToHome} className="w-fit" size="sm">
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  </ErrorLayout>
);

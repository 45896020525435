import { ConfirmActionModal, H2 } from "~/common/components";
import { TrashIcon } from "~/common/components/icons";
import { Button, Drawer, NoteData } from "~/common/components/ui";
import { CHECK_IN_CODES } from "~/common/utils";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities.ts";
import { checkInBasicData } from "~/providers/constants";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { orderTypeIcon } from "~/providers/utils/orders";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { Order } from "~/providers/utils/types/interactions.ts";
import { OrderCheckinsDetails } from "./orderCheckins/OrderCheckinsDetails";
import { OrderDescriptionLayout } from "./OrderDescriptionLayout";

interface OrderDetailsDrawerProps {
  order?: Order;
  handleClose: () => void;
  showDeleteOrder: boolean;
  toggleDeleteOrder: () => void;
  handleDelete: () => void;
  loadingDelete: boolean;
}

export const OrderDetailsDrawer = ({
  order,
  handleClose,
  handleDelete,
  loadingDelete,
  showDeleteOrder,
  toggleDeleteOrder,
}: OrderDetailsDrawerProps) => {
  const { isStaff } = useIsTeamMemberStaff();
  const { formatCheckInCode } = checkInUtilities();
  const formatCheckinCode = formatCheckInCode(order?.description);

  const isScheduleAppointment =
    order?.checkin?.code === CHECK_IN_CODES.schedule_appointment;

  return (
    <Drawer isOpen={!!order} onClose={handleClose}>
      {order ? (
        <div className="flex h-full flex-col gap-6 divide-y divide-neutral-700 overflow-y-auto">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-700">
                Assigned on {formatDate(order.created_at)}
              </p>
              {formatCheckinCode && (
                <div className="flex flex-row items-center gap-2">
                  {!isScheduleAppointment && orderTypeIcon[order.type]}
                  <H2>{formatCheckinCode}</H2>
                </div>
              )}
            </div>
            <OrderDescriptionLayout {...checkInBasicData[order.description]} />
          </div>
          <OrderCheckinsDetails order={order} />
          <div className="flex h-full w-full flex-col items-end justify-between">
            <div className="flex w-full flex-col items-start gap-6 pt-4">
              {order?.note && (
                <NoteData
                  seeMoreButton
                  title="Message for patient"
                  note={order.note}
                />
              )}
              {order?.internal_note && (
                <NoteData
                  seeMoreButton
                  title="Internal note"
                  note={order.internal_note}
                />
              )}
            </div>
            {!isStaff && (
              <Button
                variant="outline-black"
                size="sm"
                className="w-fit"
                onClick={toggleDeleteOrder}
                isLoading={loadingDelete}
              >
                <TrashIcon />
                Delete order
              </Button>
            )}
          </div>
        </div>
      ) : null}

      <ConfirmActionModal
        title="Delete order"
        isLoading={loadingDelete}
        description="Are you sure you want to delete this order?"
        show={showDeleteOrder}
        onClose={toggleDeleteOrder}
        onConfirm={handleDelete}
      />
    </Drawer>
  );
};

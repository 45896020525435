import { RegisterKeys } from "~/patients/utils/consultations/intake";

export const symptomsQuestions: Partial<Record<RegisterKeys, string>> = {
  ["symptoms.depression"]:
    "Depression: What patterns of depression are you experiencing?",
  ["symptoms.sleep_issues"]:
    "Sleep issues: What patterns of sleep issues are you experiencing?",
  ["symptoms.attention_focus_issues"]: "Attention focus issues",
  ["symptoms.anxiety"]: "Anxiety",
  ["symptoms.panic_attacks"]:
    "Panic attacks: Please describe what you are noticing. How long do they last and what do they feel like?",
  ["symptoms.increased_spending_or_shopping"]: "Increased spending or shopping",
  ["symptoms.appetite_changes"]:
    "Appetite changes: What patterns of appetite changes are you experiencing?",
  ["symptoms.nightmares"]: "Nightmares",
  ["symptoms.flashbacks"]: "Flashbacks",
  ["symptoms.memory_problems"]:
    "Problems with memory: How long have these been happening?",
  ["symptoms.food_weight_preoccupations"]:
    "Preoccupation with weight or food: What patterns of preoccupation with weight or food are you noticing?",
  ["symptoms.obsessions"]:
    "Obsessions: How much, how often and about what are your obsessions?",
  ["symptoms.compulsions"]:
    "Compulsions: How much, how often and about what are your compulsions?",
  ["symptoms.diminished_interests"]:
    "Diminished interest in previously enjoyable things",
  ["symptoms.irritability"]: "Irritability",
  ["symptoms.grand_ideas"]: "Grand ideas / Feeling “on top of the world”",
  ["symptoms.suicidal_harmful_thoughts"]:
    "Suicidal and/or harmful thoughts: What patterns of suicidal and/or harmful thoughts are you experiencing?",
  ["symptoms.impulsivity"]: "Impulsivity",
  ["symptoms.other"]: "Other symptom:",
  ["symptoms.sleep_issues.difficulty_in_falling_asleep"]:
    "Sleep issues: Difficulty in falling asleep. When did the sleep issues start?",
  ["symptoms.sleep_issues.harder_to_fall_back_asleep_once_awake"]:
    "Sleep issues: Harder to fall back asleep once awake. When did the sleep issues start?",
  ["symptoms.sleep_issues.inability_to_easily_wake_up_sleeping_late"]:
    "Sleep issues: Inability to easily wake up after sleeping late. When did the sleep issues start?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.hours_of_sleep"]:
    "Sleep issues: Decreased need for sleep. How many hours of sleep are you needing?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.happened_before"]:
    "Sleep issues: Decreased need for sleep. Has this happened before in your life?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.harder_to_stay_asleep"]:
    "Sleep issues: Decreased need for sleep. Is it harder to stay asleep or do you have mid sleep awakenings?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.early_morning_awakenings"]:
    "Sleep issues: Decreased need for sleep. Do you experience early morning awakenings before you want to wake up?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.fatigue_during_day"]:
    "Sleep issues: Decreased need for sleep. Do you experience fatigue during the day? Do you nap?",
  ["symptoms.sleep_issues.decreased_need_for_sleep.start_date"]:
    "Sleep issues: Decreased need for sleep. When did the sleep issues start?",
};

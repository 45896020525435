export const RelationshipsIcon = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" fill="#D0F9D9" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="5.5" stroke="#47D065" />
    <path
      d="M26.5 25.7656C28.6574 25.7656 30.4062 23.9293 30.4062 21.6641C30.4062 19.3988 28.6574 17.5625 26.5 17.5625C24.3426 17.5625 22.5938 19.3988 22.5938 21.6641C22.5938 23.9293 24.3426 25.7656 26.5 25.7656ZM26.5 25.7656C23.0482 25.7656 20.25 27.9692 20.25 30.6875M26.5 25.7656C29.9518 25.7656 32.75 27.9692 32.75 30.6875"
      stroke="#2AA63C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.5 21.1504C18.6574 21.1504 20.4062 19.1916 20.4062 16.7754C20.4062 14.3591 18.6574 12.4004 16.5 12.4004C14.3426 12.4004 12.5938 14.3591 12.5938 16.7754C12.5938 19.1916 14.3426 21.1504 16.5 21.1504ZM16.5 21.1504C13.0482 21.1504 10.25 23.5009 10.25 26.4004M16.5 21.1504C19.9518 21.1504 22.75 23.5009 22.75 26.4004"
      stroke="#2AA63C"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
